import React, { useEffect } from 'react'
import { PageHeaderRaw, ReloadButton } from '~/code/components'
import {
  Button,
  Card,
  Col, Divider, Drawer, List, Modal,
  Pagination,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Typography
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'
import {
  NotificationTemplatesFilter
} from '~/code/pages/Notifications/ManageNotificationTemplates/components/NotificationTemplatesFilter'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import {getTemplatesColumns} from './constants'
import {
  TemplatePreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplatePreview'
import translations from './translations'
import styles from './styles.scss'
import { removeHtmlTags } from '~/code/stores/NotificationManagementStore/services'
import { ITemplatesManagementStore } from '~/code/pages/Notifications'

export const TemplatesManagementStoreSymbol = Symbol('TemplatesManagementStoreSymbol')

export const ManageNotificationTemplates = observer(() => {
  const store = useInjection<ITemplatesManagementStore>(TemplatesManagementStoreSymbol)

  const {
    data,
    setFilter,
    isDrawerOpened,
    isLoading,
    totalCount,
    filter,
    onDrawerClose,
    newTemplate,
    templateForm,
    templatePreviewConfig,
    setApproveModalOpen,
    isApproveModalOpen,
    deleteTemplate,
    loadTemplates
  } = store

  useEffect(() => {
    loadTemplates()
  }, [])

  const screens = useBreakpoint()
  const paginationProps: TablePaginationConfig = generateTablePaginationProps(
    filter.page,
    filter.size,
    totalCount,
    (page: number, pageSize: number) => setFilter({page, size: pageSize}, true)
  )

  const columns = getTemplatesColumns(store)

  const templateDetails = [
    {
      title: translations().title,
      description: templateForm?.title,
    },
    {
      title: translations().system,
      description: templateForm?.system,
    },
    {
      title: translations().description,
      description: templateForm?.descriptionLine1
    },
    {
      title: translations().type,
      description: templateForm?.type,
    },
    {
      title: translations().displayType,
      description: templateForm?.displayType,
    },
    {
      title: translations().contentArea,
      description: templateForm?.contentArea,
    },
  ]

  return (
    <>
      <Space className={styles.flex} direction="vertical" size="large">
        <PageHeaderRaw>
          <Space direction={'vertical'} size={'small'}>
            <Row>
              <Col>
                <Typography.Title className={styles.pageTitle}  level={5}>{translations().manageTemplates}</Typography.Title>
              </Col>
            </Row>
          </Space>
        </PageHeaderRaw>

        <Card className={styles.filterCard}>
          <NotificationTemplatesFilter/>
        </Card>

        <Card>
          <Space direction="vertical" size={'middle'} style={{ display: 'flex' }}>
            <Row justify={'end'}>
              <Col className={styles.refreshBtn}>
                <ReloadButton onClick={() => setFilter({...filter}, true)}/>
              </Col>
              <Col>
                <Button
                  type={'primary'}
                  icon={<PlusOutlined />}
                  onClick={() => newTemplate()}
                  disabled={!hasPermissions([PermissionMap.notifications.templates.create])}
                >
                  {translations().newTemplate}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Table
                  loading={isLoading}
                  dataSource={data}
                  columns={columns}
                  pagination={false}
                  rowKey={(template) =>template.id}
                  scroll={{ x: 1000, y: 1000 }}
                />
              </Col>
            </Row>
            <Row justify={'end'}>
              <Col className={styles.refreshBtn}>
                <ReloadButton onClick={() => setFilter({...filter}, true)}/>
              </Col>
              <Col>
                <Pagination className={styles.bottomPagination} {...paginationProps} disabled={false} />
              </Col>
            </Row>
          </Space>
        </Card>
      </Space>

      <Drawer
        title={translations().templateDetails}
        placement='right'
        open={isDrawerOpened}
        onClose={onDrawerClose}
        width={screens.xs ? 360 : 620}
      >
        <>
          <Typography.Title level={5}>Fields</Typography.Title>
          <Divider className={styles.detailsDivider}/>
          <List
            className={styles.detailsList}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 2,
              xl: 2,
              xxl: 2
            }}
            dataSource={templateDetails}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={<span className={styles.listTitle}>{item.title}</span>}
                  description={<span className={styles.listDescription}>{removeHtmlTags(item.description)}</span>}
                />
              </List.Item>
            )}
          />
          <Typography.Title level={5}>Preview</Typography.Title>
          <Divider className={styles.detailsDivider}/>
          <Row>
            <Col span={24}>
              <TemplatePreview templateForm={templateForm} previewConfig={templatePreviewConfig}/>
            </Col>
          </Row>
        </>
      </Drawer>

      <Modal
        width={310}
        title={translations().deleteTemplateTitle}
        open={isApproveModalOpen}
        onOk={deleteTemplate}
        onCancel={() => setApproveModalOpen(false)}
      >
        {translations().deleteTemplateContent}
      </Modal>
    </>
  )
})