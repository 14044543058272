import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { inject, injectable } from 'inversify'
import moment from 'moment'
import { message } from 'antd'
import { INotificationManagementStore, INotificationsManagementStore } from '~/code/pages/Notifications'
import { NotificationsDataStore } from '~/code/stores/NotificationManagementStore/NotificationsDataStore'
import {
  NotificationsResponseType,
  NotificationType,
  PublishFormType
} from '~/code/stores/NotificationManagementStore/models'
import { createNotification, fetchNotifications } from '~/code/stores/NotificationManagementStore/services'
import {
  NotificationManagementStoreSymbol
} from '~/code/stores/NotificationManagementStore/NotificationManagementStore'
import { NotificationsFilterType } from '~/code/stores/NotificationManagementStore/models/NotificationsFilterType'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import translations from './translations'

@injectable()
export class NotificationsManagementStore implements INotificationsManagementStore {
  notificationManagementStore: INotificationManagementStore
  step: number = 0
  isPublishing = false
  dataStore = new NotificationsDataStore<NotificationsResponseType, NotificationType>(fetchNotifications)
  publishForm = {
    startDate: null,
    endDate: null,
    userType: 'all'
  }
  deactivatingNotificationId: string = null

  constructor(
    @inject(NotificationManagementStoreSymbol) notificationManagementStore: INotificationManagementStore
  ) {
    makeObservable(this, {
      step: observable,
      publishForm: observable,
      isPublishing: observable,
      deactivatingNotificationId: observable,
      isLoading: computed,
      isDrawerOpened: computed,
      filter: computed,
      templateForm: computed,
      templatePreviewConfig: computed,
      isApproveModalOpen: computed,
      totalCount: computed,
      setStep: action.bound,
      setPublishForm: action.bound,
      deactivatingNotificationHandler: action.bound,
      turnOffNotification: action.bound
    })
    this.notificationManagementStore = notificationManagementStore
  }

  get data() {
    return this.dataStore.data
  }

  get filter() {
    return this.dataStore.filter
  }

  get isDrawerOpened() {
    return this.dataStore.isDrawerOpened
  }

  get isLoading() {
    return this.dataStore.isLoading
  }

  get totalCount() {
    return this.dataStore.totalCount
  }

  get templateForm() {
    return this.notificationManagementStore.templateForm
  }

  get templatePreviewConfig() {
    return this.notificationManagementStore.templatePreviewConfig
  }

  get isApproveModalOpen() {
    return this.notificationManagementStore.isApproveModalOpen
  }

  public setStep = (value: number) => {
    this.step = value
  }

  public setFilter = (values: Partial<NotificationsFilterType>, shouldLoad?: boolean) => {
    this.dataStore.setFilter(values, shouldLoad)
  }

  public resetFilter = () => {
    this.dataStore.resetFilter()
  }
  public newTemplate = () => {
    this.setStep(0)
    goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES_CREATE)
  }

  public onDrawerClose = () => {
    this.dataStore.setDrawer(false)
  }

  public setPublishForm = (values: Partial<PublishFormType>) => {
    this.publishForm = {
      ...this.publishForm,
      ...values
    }
  }

  public deactivatingNotificationHandler = (id: string) => {
    this.deactivatingNotificationId = id
    this.setApproveModalOpen(true)
  }

  public setApproveModalOpen = (value: boolean) => {
    this.notificationManagementStore.setApproveModalOpen(value)
  }

  public goToPublishNotification = () => {
    this.setStep(0)
    goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_PUBLISH_NOTIFICATIONS)
  }

  public viewDetails = (id: string) => {
    this.notificationManagementStore.loadNotification(id).then(() => {
      this.dataStore.setDrawer(true)
    })
  }

  public republishNotification = async (id: string) => {
    await this.notificationManagementStore.loadNotification(id).then(() => {
      this.setStep(1)
      goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_PUBLISH_NOTIFICATIONS)
    })
  }

  public loadNotifications = async () => {
    await this.dataStore.loadData()
  }

  public turnOffNotification = async () => {
    this.notificationManagementStore.deactivateNotification(this.deactivatingNotificationId).then(() => {
      this.loadNotifications()
      this.deactivatingNotificationId = null
      this.setApproveModalOpen(false)
    })
  }

  public onSubmitPublishForm = async () => {
    await this.publishNotification()
    this.notificationManagementStore.templateManagementStore.setTemplateForm(null)
    goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_NOTIFICATIONS)
  }

  public publishNotification = async () => {
    runInAction(() => (this.isPublishing = true))

    const merchants =
      this.templateForm.systemId === 'dashboard'
        ? []
        : undefined
    const users = this.templateForm.systemId === 'dashboard'
      ? undefined
      : []

    const {startDate, endDate, userType} = this.publishForm

    const data = {
      notificationTemplateId: this.templateForm.id,
      isActive: true,
      data: undefined,
      users: users,
      merchants: merchants,
      startDate: moment(startDate).format(),
      endDate: moment(endDate).format(),
      userType: userType === 'all' ? undefined : userType,
    }

    try {
      const { result, error } = await createNotification(data)

      if (result) {
        message.success(translations().notificationCreated)
        goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_NOTIFICATIONS)
      }

      if (error) {
        throw new Error(error.message)
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      runInAction(() => (this.isPublishing = false))
    }
  }
}