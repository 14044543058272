import translations from './translations'

translations.add('en', {
  enterValidUrl: 'Please enter a valid URL',
  validUrl: 'Enter valid URL',
  ok: 'Ok',
  link: 'Link',
  underline: 'Underline',
  bold: 'Bold',
  italic: 'Italic'
})
