import { Col, Row, TableProps } from 'antd'
import React from 'react'
import { TemplatesDataType } from '~/code/stores/NotificationManagementStore'
import translations from './translations'

export const ITEMS = [
  {
    key: 'selectTemplate',
    title: translations().selectTemplate
  },
  {
    key: 'selectRecipient',
    title: translations().selectRecipient
  },
]

export const getColumns = (columnsMethods: {viewDetails: (id: string) => void, selectTemplateById: (id: string) => Promise<void>}): TableProps<TemplatesDataType>['columns'] => {

  const {viewDetails, selectTemplateById} = columnsMethods

  return [
    {
      title: translations().title,
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: translations().system,
      dataIndex: 'system',
      key: 'system'
    },
    {
      title: translations().displayType,
      dataIndex: 'displayType',
      key: 'displayType',
    },
    {
      title: translations().type,
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: translations().actions,
      dataIndex: 'actions',
      width: 135,
      key: 'actions',
      align: 'center',
      fixed: 'right',
      render: (value, record) => {
        return (
          <Row gutter={24} justify={'space-around'}>
            <Col>
              <a
                onClick={e => {
                  e.preventDefault()
                  viewDetails(record.id)
                }}
                href=''
              >
                {translations().view}
              </a>
            </Col>
            <Col>
              <a
                onClick={e => {
                  e.preventDefault()
                  selectTemplateById(record.id)
                }}
                href=''
              >
                {translations().select}
              </a>
            </Col>
          </Row>
        )
      }
    }
  ]
}
