import React, { useRef, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Card, Typography, Tooltip, Skeleton } from 'antd'
import { Liquid } from '@ant-design/plots'
import { InfoCircleOutlined } from '@ant-design/icons'
import { createConfig } from './constants'
import { LiquidChartProps } from './props'

import translations from './translations'
import styles from './styles.scss'

export const LiquidChart = observer(({ data }: LiquidChartProps) => {
  const chartRef = useRef<any>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [isDomLoaded, setIsDomLoaded] = useState(false)

  useEffect(() => {
    const handleDOMContentLoaded = () => {
      setIsDomLoaded(true)
    }

    if (document.readyState === 'complete') {
      handleDOMContentLoaded()
    } else {
      window.addEventListener('DOMContentLoaded', handleDOMContentLoaded)
    }

    return () => {
      window.removeEventListener('DOMContentLoaded', handleDOMContentLoaded)
    }
  }, [])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (containerRef.current && chartRef.current) {
        chartRef.current.changeSize(containerRef.current.offsetWidth, containerRef.current.offsetHeight)
      }
    })

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => resizeObserver.disconnect()
  }, [])

  return (
    <Card>
      <div className={styles.titleRow}>
        <Typography.Title level={5} className={styles.title}>
          {translations().title}
        </Typography.Title>
        <Tooltip title={translations().tooltipText}>
          <InfoCircleOutlined className={styles.infoIcon} />
        </Tooltip>
      </div>

      <div ref={containerRef} className={styles.chartHolder}>
        {!Boolean(data) && <Skeleton className={styles.skeleton} active />}

        {isDomLoaded && Boolean(data) && <Liquid {...createConfig(data, chartRef)} />}
      </div>
    </Card>
  )
})
