import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    comingSoon: string
    maintenanceIncident: string
    maintenanceImage: string
    push: string
    marketing: string
    description: string
    title: string
    merchantPortal: string
    dashboard: string
    bpm: string
    name: string
    creatingMaintenance: string
    pushImage: string
    marketingImage: string
    instructions: string
    typeSelection: string
    selectType: string
    fillingDetails: string
    makeSure: string
    preview: string
    requiredPreview: string
    creatingMarketing: string
    loading: string
}>()

