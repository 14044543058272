import React from 'react'
import { Bar, Circle } from '~/code/components/NotificationsManagement/Preview/shared'
import { Flex } from 'antd'
import { alto, candleLight } from '~/code/constants/colors'
import {
  TemplateConfigProviderType
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components'
import styles from './styles.scss'

export const PreviewHeader = ({templateConfig}: {templateConfig: TemplateConfigProviderType}) => {
  const {previewConfig} = templateConfig

  return (
   <>
    <div className={styles.header} style={{...previewConfig.header}}>
      <Flex className={styles.fullWidth} justify={'space-between'}>
        <Flex gap={5}>
          <Circle color={candleLight} diameter={20}/>
          <Bar width={50} height={10} color={alto}/>
        </Flex>
        <Flex gap={5}>
          <Bar width={50} height={10} color={alto}/>
          <Circle color={alto} diameter={20}/>
        </Flex>
      </Flex>
    </div>
   </>
  )
}