import React from 'react'
import styles from './styles.scss'

export const TableHeader = () => {
  return (
    <div className={styles.header}>
      <div className={styles.headerItem} />
    </div>
  )
}
