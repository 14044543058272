import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import {
  Button,
  Card,
  Col, Divider,
  Drawer, List,
  Modal,
  Pagination,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Typography
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'
import { INotificationsManagementStore } from '~/code/pages/Notifications'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import { PageHeaderRaw, ReloadButton } from '~/code/components'
import { ManageNotificationsFilter } from '~/code/pages/Notifications/ManageNotifications/components'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { removeHtmlTags } from '~/code/stores/NotificationManagementStore/services'
import {
  TemplatePreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components'
import {getColumns} from './constants'
import translations from './translations'
import styles from './styles.scss'



export const NotificationsManagementStoreSymbol = Symbol('NotificationsManagementStoreSymbol')

export const ManageNotifications = observer(() => {
  const store = useInjection<INotificationsManagementStore>(NotificationsManagementStoreSymbol)

  const {
    filter,
    data,
    isLoading,
    setFilter,
    totalCount,
    isDrawerOpened,
    templateForm,
    templatePreviewConfig,
    turnOffNotification,
    isApproveModalOpen,
    goToPublishNotification,
    onDrawerClose,
    setApproveModalOpen,
    loadNotifications
  } =  store

  useEffect(() => {
    loadNotifications()
  }, [])

  const screens = useBreakpoint()

  const paginationProps: TablePaginationConfig = generateTablePaginationProps(
    filter.page,
    filter.size,
    totalCount,
    (page: number, pageSize: number) => setFilter({page, size: pageSize}, true)
  )

  const columns = getColumns(store)

  const notificationDetails = () => {
    const notification = data.find((notification) => notification.notificationTemplateId === templateForm?.id)

    return [
      {
        title: translations().title,
        description: templateForm?.title,
      },
      {
        title: translations().system,
        description: templateForm?.system,
      },
      {
        title: translations().description,
        description: templateForm?.descriptionLine1,
      },
      {
        title: translations().type,
        description: templateForm?.type,
      },
      {
        title: translations().displayType,
        description: templateForm?.displayType,
      },
      {
        title: translations().contentArea,
        description: templateForm?.contentArea,
      },
      {
        title: translations().userType,
        description: notification?.userType ? translations()[notification?.userType] : undefined
      },
    ]
  }

  const filteredData = notificationDetails().filter(item => item.description)

  return (
    <>
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        <PageHeaderRaw>
          <Space direction={'vertical'} size={'small'}>
            <Row>
              <Col>
                <Typography.Title className={styles.pageTitle} level={5}>{translations().manageNotifications}</Typography.Title>
              </Col>
            </Row>
          </Space>
        </PageHeaderRaw>

        <Card className={styles.filterCard}>
          <ManageNotificationsFilter/>
        </Card>

        <Card>
          <Space direction="vertical" size={'middle'} style={{ display: 'flex' }}>
            <Row justify={'end'}>
              <Col className={styles.refreshBtn}>
                <ReloadButton onClick={() => setFilter({...filter}, true)}/>
              </Col>
              <Col>
                <Button
                  disabled={!hasPermissions([PermissionMap.notifications.create])}
                  type={'primary'}
                  icon={<PlusOutlined />}
                  onClick={() => goToPublishNotification()}
                >
                  {translations().newNotification}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Table
                  loading={isLoading}
                  dataSource={data}
                  columns={columns}
                  pagination={false}
                  rowKey={({id}) =>id}
                  scroll={{ x: 1000, y: 1000 }}
                />
              </Col>
            </Row>
            <Row justify={'end'}>
              <Col className={styles.refreshBtn}>
                <ReloadButton onClick={() => setFilter({...filter}, true)}/>
              </Col>
              <Col>
                <Pagination className={styles.bottomPagination} {...paginationProps} disabled={false} />
              </Col>
            </Row>
          </Space>
        </Card>

        <Modal
          width={310}
          title={translations().deactivateTitle}
          open={isApproveModalOpen}
          onOk={turnOffNotification}
          onCancel={() => setApproveModalOpen(false)}
        >
          {translations().deactivateContent}
        </Modal>
      </Space>

      <Drawer
        title={translations().notificationDetails}
        placement='right'
        open={isDrawerOpened}
        onClose={onDrawerClose}
        width={screens.xs ? 360 : 620}
      >
        <>
          <Typography.Title level={5}>Fields</Typography.Title>
          <Divider className={styles.detailsDivider}/>
          <List
            className={styles.detailsList}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 2,
              xl: 2,
              xxl: 2
            }}
            dataSource={filteredData}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={<span className={styles.listTitle}>{item.title}</span>}
                  description={<span className={styles.listDescription}>{removeHtmlTags(item.description)}</span>}
                />
              </List.Item>
            )}
          />
          <Typography.Title level={5}>Preview</Typography.Title>
          <Divider className={styles.detailsDivider}/>
          <Row>
            <Col span={24}>
              <TemplatePreview templateForm={templateForm} previewConfig={templatePreviewConfig}/>
            </Col>
          </Row>
        </>
      </Drawer>
    </>
  )
})