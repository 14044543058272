import moment from 'moment'
import { RealTimeTPSDataModel, RealTimeTPSHistoryType } from '~/code/stores/RealTimeTransactionStore/models'

export const transformToChartData = (rawData: RealTimeTPSDataModel[]): RealTimeTPSHistoryType => {
  const transformed = rawData.flatMap(({ current, historical, timestamp }) => {
    const time = moment(timestamp).format('HH:mm')
    return [
      { name: 'current', tps: current.averageTps, time },
      { name: 'historical', tps: historical.averageTps, time }
    ]
  })

  const buildDataset = (name: 'current' | 'historical', color: string) => ({
    label: name,
    backgroundColor: color,
    borderWidth: 1,
    data: transformed.filter(item => item.name === name).map(item => item.tps)
  })

  const labels = transformed.filter(item => item.name === 'current').map(item => item.time)

  return {
    labels,
    datasets: [
      buildDataset('historical', 'rgba(24, 143, 255, 0.35)'),
      buildDataset('current', 'rgba(24, 143, 255, 0.7)')
    ]
  }
}
