import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row, Select, Space, Tabs } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react'
import { TabsProps } from 'antd/lib/tabs'
import { hasPermissions } from '~/code/services/auth'
import { useForm } from 'antd/es/form/Form'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { INotificationsManagementStore, NotificationsManagementStoreSymbol } from '~/code/pages/Notifications'
import {
  WebPreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplatePreview/components/WebPreview'
import {
  MobilePreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplatePreview/components/MobilePreview'
import {
  TemplateConfigProvider
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplatePreview/components/TemplateConfigProvider'
import {
  PreviewStepTableFilter
} from '~/code/pages/Notifications/ManageNotifications/pages/PublishNotification/components'
import translations from './translations'

export const PreviewStep = observer(() => {
  const store = useInjection<INotificationsManagementStore>(NotificationsManagementStoreSymbol)

  const {
    templateForm,
    publishForm,
    setPublishForm,
    templatePreviewConfig,
    isPublishing,
    onSubmitPublishForm
  } = store

  const {notificationContentAreaId, systemId} = templateForm

  const isDashboard = systemId === 'dashboard'
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [isConfirmationModalOpen, setConfirmationModal] = useState(false)
  const [form] = useForm()

  useEffect(() => {
    checkFormValidity()
    return () => clearForm()
  }, [])

  const clearForm = () => {
    setPublishForm({
      userType: 'all',
      startDate: null,
      endDate: null
    })
    form.resetFields()
  }

  const getInitialValues = () => {
    const isAuthArea = notificationContentAreaId === 'login' || notificationContentAreaId === 'app'
    if (isDashboard && !isAuthArea) return {...publishForm, userType: null}
    return publishForm
  }

  const checkFormValidity = () => {
    const fieldsError = form.getFieldsError()
    const hasErrors = fieldsError.some(({ errors }) => errors.length > 0)
    const allValues = form.getFieldsValue()
    const requiredFieldsFilled = Object.values(allValues).every(value => value && (value !== ''))
    const formIsValid = !hasErrors && requiredFieldsFilled

    setIsFormComplete(!formIsValid)
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: translations().web,
      children: <WebPreview />,
    },
    {
      key: '2',
      label: translations().mobile,
      children: <MobilePreview />,
    },
  ]

  const providerValue = {
    templateForm,
    previewConfig: templatePreviewConfig
  }

  const renderSelectOptions = (isDashboard: boolean) => {
    const {notificationContentAreaId} = templateForm

    const isAuthArea = notificationContentAreaId === 'login' || notificationContentAreaId === 'app'

    const mpOptions = [{ value: 'all', label: translations().all }]
    const dashboardOptions = [
      { value: 'all', label: translations().all },
      { value: 'employee', label: translations().employee },
      { value: 'partner', label: translations().partner }
    ]

    return !isDashboard || isAuthArea ? mpOptions : dashboardOptions
  }

  const okBtnHandler = async () => {
    await onSubmitPublishForm().then(() => setConfirmationModal(false))
  }

  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Card>
          <PreviewStepTableFilter form={form} />
        </Card>

        <Row gutter={[8, 8]}>
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <TemplateConfigProvider value={providerValue}>
              <Card title={translations().notificationsPreview(isDashboard ? translations().dashboard : translations().mp)}>
                <Tabs defaultActiveKey="1" items={items} />
              </Card>
            </TemplateConfigProvider>
          </Col>
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <Card title={translations().recipientsPreview}>
              <Row>
                <Col sm={24}>
                  <Form
                    form={form}
                    onValuesChange={() => checkFormValidity()}
                    initialValues={getInitialValues()}
                  >
                    <Form.Item
                      name='userType'
                      label={translations().publishTo}
                      rules={[{required: true, message: translations().fieldRequired}]}
                    >
                      <Select defaultValue={'all'} onChange={(value) => setPublishForm({userType: value})}>
                        {renderSelectOptions(isDashboard).map(({value, label}) => (
                          <Select.Option key={label} value={value}>{label}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form>

                </Col>
                <Col sm={24} style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    htmlType={'submit'}
                    disabled={isFormComplete || !hasPermissions([PermissionMap.notifications.create])} // Disable based on form completeness
                    type="primary"
                    onClick={() => setConfirmationModal(true)}
                    loading={isPublishing}
                  >
                    {translations().publish}
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Space>
      <Modal
        open={isConfirmationModalOpen}
        onClose={() => setConfirmationModal(false)}
        onCancel={() => setConfirmationModal(false)}
        title={translations().confirmation}
        onOk={okBtnHandler}
      >
        <Row>
          <Col xs={24}>
            {translations().aboutToPublish}
          </Col>
          <Col xs={24}>
            {`${translations()[publishForm.userType]} ${isDashboard 
              ? translations().confirmationTextDashboard 
              : translations().confirmationTextMp}`}
          </Col>
        </Row>
      </Modal>
    </>
  )
})