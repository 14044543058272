import React from 'react'
import { observer } from 'mobx-react'
import { Tabs } from 'antd'
import { TabsProps } from 'antd/lib/tabs'
import {
  WebPreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplatePreview/components/WebPreview'
import {
  MobilePreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplatePreview/components/MobilePreview'
import { TemplatePreviewProps } from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplatePreview/props'
import { TemplateConfigProvider } from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplatePreview/components/TemplateConfigProvider'
import translations from './translations'

export const TemplatePreview = observer(({templateForm, previewConfig}: TemplatePreviewProps) => {

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: translations().web,
      children: <WebPreview/>
    },
    {
      key: '2',
      label: translations().mobile,
      children: <MobilePreview/>
    },
  ]

  const providerValue = {
    templateForm,
    previewConfig
  }

  return (
    <TemplateConfigProvider value={providerValue}>
      <Tabs defaultActiveKey="1" items={items}/>
    </TemplateConfigProvider>
  )
})
