import { PresetStatusColorType } from 'antd/es/_util/colors'
import moment, { Moment } from 'moment-timezone'
import * as DOMPurify from 'dompurify'
import {
  StatusType,
  TransactionChannelType,
  PosTransactionChannelType,
  PaymentMethodType,
  PeriodType,
  BpmStatusType,
  SignifydDecisionType,
  GroupableItem
} from '~/code/models'
import { DEFAULT_TIMEZONE } from '~/code/constants/date-time'
import { PAYMENT_METHODS, TRANSACTION_CHANNELS, POS_TRANSACTION_CHANNELS } from '../constants/dictionaries'

export function formatAmount(value: number, decimalLength = 2, sep = ' ') {
  const valueString = Number(value || 0).toFixed(decimalLength)
  return valueString && valueString.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + sep)
}

export function createCheckColumnDate(dateColumn: {}) {
  return (date: string) => {
    if (!dateColumn[date]) {
      dateColumn[date] = true
      return true
    }
    return false
  }
}

export function clearObject(obj: object) {
  Object.keys(obj).forEach(key => {
    delete obj[key]
  })
}

export function clearMobx(obj: object) {
  if (!obj) return obj
  return JSON.parse(JSON.stringify(obj))
}

export const onCell = (row: GroupableItem<any>): React.TdHTMLAttributes<any> => {
  return {
    colSpan: !row.groupTitle ? 1 : 0
  }
}

export const isSet = (value: any) => value !== null && value !== undefined

export const isDateNull = (date: string) => !date || date === '0000-12-31T23:58:45-00:01'

export const getDates = (period: PeriodType) => {
  let startDate: moment.Moment = null
  let endDate: moment.Moment = null

  switch (period) {
    case PeriodType.Day:
      startDate = moment().startOf('day')
      endDate = moment().endOf('day')
      break
    case PeriodType.Yesterday:
      startDate = moment().subtract(1, 'days').startOf('day')
      endDate = moment().subtract(1, 'days').endOf('day')
      break
    case PeriodType.Week:
      startDate = moment().startOf('isoWeek')
      endDate = moment().endOf('day')
      break
    case PeriodType.LastWeek:
      startDate = moment().startOf('isoWeek').subtract(1, 'week').startOf('day')
      endDate = moment().startOf('isoWeek').subtract(1, 'day').endOf('day')
      break
    case PeriodType.Month:
      startDate = moment().startOf('month')
      endDate = moment().endOf('day')
      break
    case PeriodType.LastMonth:
      startDate = moment().startOf('month').subtract(1, 'month').startOf('day')
      endDate = moment().startOf('month').subtract(1, 'day').endOf('day')
      break
    case PeriodType.Year:
      startDate = moment().startOf('year')
      endDate = moment().endOf('day')
      break
    case PeriodType.Last7Days:
      startDate = moment().subtract(6, 'days').startOf('day')
      endDate = moment().endOf('day')
      break
    case PeriodType.Last30Days:
      startDate = moment().subtract(29, 'days').startOf('day')
      endDate = moment().endOf('day')
      break
    case PeriodType.Last60Days:
      startDate = moment().subtract(59, 'days').startOf('day')
      endDate = moment().endOf('day')
      break
    case PeriodType.Last90Days:
      startDate = moment().subtract(89, 'days').startOf('day')
      endDate = moment().endOf('day')
      break
    case PeriodType.Last12Months:
      startDate = moment().subtract(12, 'months').startOf('day')
      endDate = moment().endOf('day')
      break
  }

  return { startDate, endDate }
}

export const getPeriod = (startDate: moment.Moment, endDate: moment.Moment): PeriodType => {
  const enumKeys = Object.keys(PeriodType)
  return enumKeys.reduce<PeriodType>((resultVal, current) => {
    const periodEnum = PeriodType[current]
    const dates = getDates(periodEnum)
    if (startDate?.isSame(dates.startDate) && endDate.isSame(dates.endDate)) {
      resultVal = periodEnum
    }
    return resultVal
  }, PeriodType.Custom)
}

export const getStatusColor = (status: StatusType): PresetStatusColorType => {
  switch (status) {
    case 'AUTH':
    case 'CHARGE':
    case 'TOKENIZED':
    case 'VERIFIED':
    case 'CREDITED':
      return 'success'
    case 'CANCEL':
    case 'REFUND':
    case 'PAYMENT_LINK_CANCELLED':
      return 'default'
    case 'REJECT':
    case 'FAILED':
      return 'error'
    case 'NEW':
    case '3D':
    case 'CREATED':
    case 'PROCESSING':
    case 'PAYMENT_LINK_VIEWED':
      return 'processing'
    default:
      return 'default'
  }
}

export const getSignifydColor = (decision: SignifydDecisionType): PresetStatusColorType => {
  switch (decision) {
    case 'ACCEPT':
      return 'success'
    case 'REJECT':
      return 'error'
    case 'HOLD':
      return 'warning'
    default:
      return 'default'
  }
}

export const getBpmStatusColor = (status: BpmStatusType): PresetStatusColorType => {
  switch (status) {
    case 'completed':
      return 'success'
    case 'withdrawal':
      return 'default'
    case 'rejected':
      return 'error'
    case 'initiated':
    case 'inProcess':
      return 'processing'
    default:
      return 'default'
  }
}

export const getTransactionChannelText = (channel: TransactionChannelType) => {
  const item = TRANSACTION_CHANNELS.find(c => c.value === channel)
  return item ? item.label : channel
}

export const getPosTransactionChannelText = (channel: PosTransactionChannelType) => {
  const item = POS_TRANSACTION_CHANNELS.find(c => c.value === channel)
  return item ? item.label : channel
}

const paymentMethods = PAYMENT_METHODS
export const getPaymentMethodTypeText = (method: PaymentMethodType) => {
  const item = paymentMethods.find(m => m.value === method)
  return item ? item.label : method
}

export const getCurrentTimeZone = () => {
  // TODO later remove default timezone from here
  return DEFAULT_TIMEZONE || Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function trimObject<T extends {}>(obj: T): T {
  const trimmedData = {}

  for (const [key, value] of Object.entries(obj)) {
    trimmedData[key] = (() => {
      if (!value) return value
      switch (typeof value) {
        case 'string':
          return value.trim()
        case 'object':
          return Array.isArray(value) ? value.map(item => trimObject(item)) : trimObject(value)
      }
      return value
    })()
  }

  return trimmedData as T
}

export const isObject = (value: any) => {
  return !!(value && typeof value === 'object' && !Array.isArray(value))
}

export const getNestedObjectValue = (obj: object, keys: string[]) => {
  let result = obj
  for (const key of keys) {
    if (isObject(result)) {
      result = result[key]
    } else {
      return undefined
    }
  }
  return result
}

export const traverseObject = (obj: object, func: (value: any, key: string, obj: object) => void) => {
  const entries = Object.entries(obj)
  for (const [key, value] of entries) {
    if (isObject(value)) {
      traverseObject(value, func)
    } else {
      func(value, key, obj)
    }
  }
}

export const getPrevDates = (startDate: Moment, endDate: Moment, period: PeriodType) => {
  if (!startDate || !endDate || !period) {
    return {
      prevStartDate: null,
      prevEndDate: null
    }
  }

  const prevEndDate = startDate.clone().subtract(1, 'minute')
  switch (period) {
    case PeriodType.Week:
      return {
        prevStartDate: startDate.clone().subtract(1, 'week'),
        prevEndDate
      }
    case PeriodType.Month:
      return {
        prevStartDate: startDate.clone().subtract(1, 'month'),
        prevEndDate
      }
    case PeriodType.Year:
      return {
        prevStartDate: startDate.clone().subtract(1, 'year'),
        prevEndDate
      }
    default:
      const diff = endDate.clone().diff(startDate, 'minutes')
      return {
        prevStartDate: startDate.clone().subtract(diff + 1, 'minutes'),
        prevEndDate
      }
  }
}

export const isNull = (num: number) => !num && num !== 0

export const capitalizeFirstLetter = (str: string): string => {
  if (typeof str !== 'string') return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const notificationDescription = (description: string) => {
  return (DOMPurify as any).default.sanitize(description || '')
}
