import translations from './translations'

translations.add('en', {
    comingSoon: 'Coming Soon',
    maintenanceIncident: 'Maintenance/Incident',
    maintenanceImage: 'Maintenance Image',
    push: 'Push',
    marketing: 'Marketing',
    description: 'Description',
    title: 'Title',
    merchantPortal: 'Merchant Portal',
    dashboard: 'Dashboard',
    bpm: 'BPM',
    name: 'Name',
    creatingMaintenance: 'Creating maintenance/incident notifications',
    pushImage: 'Push Image',
    marketingImage: 'Marketing Image',
    instructions: 'Instructions',
    typeSelection: 'Type selection',
    selectType: 'Select the correct notification type',
    fillingDetails: 'Filling details',
    makeSure: 'Ensure that all details are entered correctly',
    preview: 'Preview',
    requiredPreview: 'You must preview the notification before saving it',
    creatingMarketing: 'Creating marketing notifications',
    loading: 'Loading...'
})
