import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { inject, injectable } from 'inversify'
import { ITemplateManagementStore, ITemplatesManagementStore } from '~/code/pages/Notifications'
import { NotificationsDataStore } from '~/code/stores/NotificationManagementStore/NotificationsDataStore'
import { TemplatesResponseType } from '~/code/stores/NotificationManagementStore/models/TemplatesResponseType'
import { NotificationTemplateEnum, TemplatesDataType } from '~/code/stores/NotificationManagementStore/models'
import { fetchTemplates } from '~/code/stores/NotificationManagementStore/services'
import {
  TemplateManagementStoreSymbol
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components'
import { NotificationsFilterType } from '~/code/stores/NotificationManagementStore/models/NotificationsFilterType'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'

@injectable()
export class TemplatesManagementStore implements ITemplatesManagementStore {
  templateManagementStore: ITemplateManagementStore
  dataStore = new NotificationsDataStore<TemplatesResponseType, TemplatesDataType>(fetchTemplates)
  step: number = 0
  deletingTemplateId: string = null
  constructor(
    @inject(TemplateManagementStoreSymbol) templateManagementStore: ITemplateManagementStore
  ) {
    makeObservable(this, {
      step: observable,
      setStep: action.bound,
      deleteActionHandler: action.bound,
      isSystemOptionsLoading: computed,
      data: computed,
      isDrawerOpened: computed,
      isLoading: computed,
      totalCount: computed,
      filter: computed,
      templateForm: computed,
      templatePreviewConfig: computed,
      isApproveModalOpen: computed
    })
    this.templateManagementStore = templateManagementStore
  }

  get data() {
    return this.dataStore.data
  }

  get filter() {
    return this.dataStore.filter
  }

  get isDrawerOpened() {
    return this.dataStore.isDrawerOpened
  }

  get isLoading() {
    return this.dataStore.isLoading
  }

  get totalCount() {
    return this.dataStore.totalCount
  }

  get isSystemOptionsLoading() {
    return this.templateManagementStore.isSystemOptionsLoading
  }

  get templateForm() {
    return this.templateManagementStore.templateForm
  }

  get templatePreviewConfig() {
    return this.templateManagementStore.templatePreviewConfig
  }

  get isApproveModalOpen() {
    return this.templateManagementStore.isApproveModalOpen
  }

  public setStep = (value: number) => {
    this.step = value
  }

  public setFilter = (values: Partial<NotificationsFilterType>, shouldLoad?: boolean) => {
    this.dataStore.setFilter(values, shouldLoad)
  }

  public resetFilter = () => {
    this.dataStore.resetFilter()
  }
  public newTemplate = () => {
    this.templateManagementStore.clearTemplateForm()
    this.setStep(0)
    goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES_CREATE)
  }

  public setTemplateType = (type: NotificationTemplateEnum) => {
    this.templateManagementStore.fetchSystems().then(() => {
      this.templateManagementStore.setTemplateForm({notificationTypeId: type})
      this.setStep(1)
    })
  }

  public viewDetails = (id: string) => {
    this.templateManagementStore.loadData(id).then(() => {
      this.dataStore.setDrawer(true)
    })
  }

  public selectTemplate = async (id: string) => {
    await this.templateManagementStore.loadData(id)
    await this.templateManagementStore.fetchOptions()
  }

  public editTemplate = (id: string) => {
    this.selectTemplate(id).then(() => {
      this.setStep(1)
      goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES_EDIT, {id})
    })
  }

  public setApproveModalOpen = (value: boolean) => {
    this.templateManagementStore.setApproveModalOpen(value)
  }

  public deleteActionHandler = (id: string) => {
    this.deletingTemplateId = id
    this.setApproveModalOpen(true)
  }

  public deleteTemplate = () => {
    this.templateManagementStore.deleteTemplate(this.deletingTemplateId).then(() => {
      this.loadTemplates()
      runInAction(() => this.deletingTemplateId = null)
    })
  }

  public onDrawerClose = () => {
    this.dataStore.setDrawer(false)
  }

  public loadTemplates = async () => {
   await this.dataStore.loadData()
  }
}