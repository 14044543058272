import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { inject, injectable } from 'inversify'
import { message } from 'antd'
import { INotificationManagementStore, ITemplateManagementStore } from '~/code/pages/Notifications'
import { NotificationType } from '~/code/stores/NotificationManagementStore/models'
import {
  TemplateManagementStoreSymbol
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components'
import { deactivateNotificationById, fetchNotification } from '~/code/stores/NotificationManagementStore/services'
import translations from './translations'

export const NotificationManagementStoreSymbol = Symbol('NotificationManagementStoreSymbol')

@injectable()
export class NotificationManagementStore implements INotificationManagementStore {
  isLoading: boolean = false
  isApproveModalOpen: boolean = false
  templateManagementStore: ITemplateManagementStore = null
  notification: NotificationType = null
  constructor(
    @inject(TemplateManagementStoreSymbol) templateManagementStore: ITemplateManagementStore
  ) {
    makeObservable(this, {
      isLoading: observable,
      isApproveModalOpen: observable,
      notification: observable,
      templateForm: computed,
      templatePreviewConfig: computed,
      previewConfig: computed,
      setApproveModalOpen: action.bound,
    })
    this.templateManagementStore = templateManagementStore
  }

  get templateForm() {
    return this.templateManagementStore.templateForm
  }

  get previewConfig() {
    return this.templateManagementStore.templatePreviewConfig
  }

  get templatePreviewConfig() {
    return this.templateManagementStore.templatePreviewConfig
  }

  public setApproveModalOpen = (value: boolean) => {
    this.isApproveModalOpen = value
  }

  public loadNotification = async (id: string) => {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const {result, error} = await fetchNotification(id)
      if (error) throw new Error(error.message)

      runInAction(() => this.notification = result)
      await this.templateManagementStore.loadData(this.notification.notificationTemplateId)

    } catch(error) {
      message.error(error.message)
    } finally {
      runInAction(() => this.isLoading = false)
    }
  }

  public deactivateNotification = async (id: string) => {
    try {
      const { result } = await deactivateNotificationById(id)

      if (result) {
        message.success(translations().notificationDeactivated)
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  public loadTemplate = async (id: string) => {
    await this.templateManagementStore.loadData(id)
  }
}