import React from 'react'
import { observer } from 'mobx-react'
import { NotificationDisplayTypeEnum } from '~/code/models'
import { useTemplateConfig } from '~/code/hooks'
import {
  BannerMobilePreview, FullBlockMobilePreview, PopupMobilePreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplatePreview/components/MobilePreview/components'
import {
  LoginBannerPreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components'

export const MobilePreview = observer(() => {

  const { templateForm}  = useTemplateConfig()

  const renderPreview = () => {
    const {notificationDisplayTypeId, notificationContentAreaId} = templateForm

    switch (notificationDisplayTypeId) {
      case(NotificationDisplayTypeEnum.BANNER):
        if (notificationContentAreaId === 'login') return <LoginBannerPreview/>
        return <BannerMobilePreview/>
      case(NotificationDisplayTypeEnum.POPUP):
        return <PopupMobilePreview/>
      case(NotificationDisplayTypeEnum.BLOCK):
        return <FullBlockMobilePreview/>
      default: return null
    }
  }

  return (
    <>{renderPreview()}</>
  )
})