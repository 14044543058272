import React from 'react'
import translations from './translations'
import { SettlementFormValuesType } from '../models'
import { grossAvailableLength } from '../constants'
import styles from '../ChangeSettlementType.scss'

translations.add('en', {
  errorDefiningSettlementType: 'Settlement type is not defined. Please, contact process-team',
  settlementType: 'Current settlement type: ',
  chargeMerchant: 'To charge the merchant by:',
  changeSettlement: 'Change Settlement Type',
  dailyNet: 'Daily Net',
  monthlyNet: 'Monthly Net',
  grossDD: 'Gross Direct Debit',
  dailyGross: 'Daily Gross',
  submit: 'Submit',
  settlementPayment: 'Settlement Payment',
  perOutlerMid: 'Per Outlet / MID',
  perCompanyUberId: 'Per Company / UBER ID',
  title: 'Settlement terms',
  settlementTypeNote: () => (
    <>
      <br />
      <div>Please note that settlements configuration has certain limitations.</div>
      <div className={styles.styledDisclaimerTitle}>
        <ul>
          <>
            <li>
              Gross Settlement per UBER ID is not available if different Direct Debit accounts are linked to the Outlets
            </li>
            <li>Gross Settlement per MID is not available if Settlement Payment is set by UBER ID</li>
          </>
        </ul>
      </div>
    </>
  ),
  dailyNetSettlements: 'Daily Net settlements',
  monthlyNetSettlements: 'Monthly Net settlements',
  grossSettlementsByMid: (
    numberOfBankDetailsForDirectDebit: number,
    settlementFormValues: SettlementFormValuesType
  ) => (
    <>
      <div>Gross settlements by MID level</div>
      {!settlementFormValues?.grossSettlementsByMid && numberOfBankDetailsForDirectDebit < grossAvailableLength && (
        <div className={styles.errorText}>
          Gross settlement is not available for configuration, you need to add Direct Debit account(s) to the store(s)
          first.
        </div>
      )}
    </>
  ),
  grossSettlementsByUberId: (
    numberOfBankDetailsForDirectDebit: number,
    settlementFormValues: SettlementFormValuesType
  ) => (
    <>
      <div>Gross settlements by UBER ID level</div>
      {!settlementFormValues?.grossSettlementsByUberId &&
        numberOfBankDetailsForDirectDebit !== grossAvailableLength && (
          <div className={styles.errorText}>
            {numberOfBankDetailsForDirectDebit > grossAvailableLength
              ? 'Gross settlements by UBER ID level is unavailable'
              : 'Gross settlement is not available for configuration, you need to add Direct Debit account(s) to the store(s) first.'}
          </div>
        )}
    </>
  ),
  settlementImmediate: 'Process change settlement type request immediately',
  settlementPaymentNote: 'For information only, impossible to amend.',
  byMid: ' settlements by MID level',
  byUberId: ' settlements by UBER ID level',
  settlementImmediateNote: () => (
    <>
      <div className={styles.styledDisclaimerTitle}>
        <ul>
          <>
            <li>
              Activate if it is required to change settlement type immediately. Otherwise, the request will be processed
              on the 1st day of the next month.
            </li>
            <li>
              Please note that switching of the already transacting Merchants to Gross can cause inconsistent statements
              and invoices for the current month.
            </li>
            <li>
              Due to technical reasons the requests to change settlement type to Daily Net can be processed only on the
              1st day of the next month.
            </li>
          </>
        </ul>
      </div>
    </>
  )
})
