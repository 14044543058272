import React from 'react'
import { Flex } from 'antd'
import { Bar, Circle } from '~/code/components/NotificationsManagement/Preview/shared'
import {
  TemplateConfigProviderType
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components'
import { alto } from '~/code/constants/colors'
import styles from './styles.scss'

export const PreviewSider = ({templateConfig}: {templateConfig: TemplateConfigProviderType}) => {
  const {previewConfig} = templateConfig

  const renderMenuItems = () => (
    Array.from({ length: 10 }, (_, index) => (
      <div className={styles.itemContainer} key={index}>
        <Flex gap={5} justify={'space-between'}>
          <Circle diameter={15} color={alto}/>
          <Bar width={50} height={5} color={alto}/>
        </Flex>
      </div>
    ))
  )

  return (
    <div className={styles.sider} style={{...previewConfig.sider}}>
      {renderMenuItems()}
    </div>
  )
}