import { useContext } from 'react'
import {
  TemplateConfigContext
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplatePreview/components/TemplateConfigProvider'

export const useTemplateConfig = () => {
  const context = useContext(TemplateConfigContext)
  if (!context) {
    throw new Error('useNotificationConfig must be used within a NotificationConfigProvider')
  }
  return context
}