import React from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { Card, Typography, Tooltip, Skeleton } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { BarChart } from '~/code/components'
import { ColumnChartProps } from './props'

import translations from './translations'
import styles from './styles.scss'

export const ColumnChart = observer(({ data }: ColumnChartProps) => {
  const hasData = data?.labels?.length > 0

  return (
    <Card>
      <div className={styles.titleRow}>
        <Typography.Title level={5} className={styles.title}>
          {translations().title}
        </Typography.Title>
        <Tooltip title={translations().tooltipText}>
          <InfoCircleOutlined className={styles.infoIcon} />
        </Tooltip>
      </div>

      {!hasData ? (
        <Skeleton className={styles.skeleton} active />
      ) : (
        <BarChart data={toJS(data)} className={styles.chart} />
      )}
    </Card>
  )
})
