import React, { useEffect, useRef, useState } from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Button, Card, Flex, Form, Input, Popover, Tooltip } from 'antd'
import { BoldOutlined, ItalicOutlined, UnderlineOutlined, LinkOutlined } from '@ant-design/icons'
import Link from '@tiptap/extension-link'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Underline } from '@tiptap/extension-underline'
import { removeHtmlTags } from '~/code/stores/NotificationManagementStore/services'
import translations from './translations'
import styles from './styles.scss'

export const TiptapEditor: React.FC<{ onChange: (value: string) => void, value: string }> = ({ onChange, value }) => {
  const [isPopoverOpened, setPopover] = useState(false)
  const [url, setUrl] = useState<string>('')
  const inputRef = useRef(null)
  const [form] = Form.useForm()

  const editor = useEditor({
    extensions: [StarterKit, Link, Paragraph, Underline],
    content: value || '',
    editorProps: {
      attributes: {
        class: styles.customEditor,
      },
      handleDOMEvents: {
        beforeinput: (view, event) => {
          const contentText = removeHtmlTags(editor.getHTML())

          if (contentText.length >= 200 && event.inputType === 'insertText') {
            event.preventDefault()
            return true
          }
          return false
        },
        paste: (view, event) => {
          event.preventDefault()

          let pasteText = event.clipboardData?.getData('text') || ''

          pasteText = removeHtmlTags(pasteText).trim()
          const contentText = removeHtmlTags(editor.getHTML())

          if (contentText.length + pasteText.length > 200) {
            pasteText = pasteText.substring(0, 200 - contentText.length)
          }

          editor.commands.insertContent(pasteText)
        }
      },
    },
    onUpdate({ editor }) {
      const contentText = removeHtmlTags(value)
      if (contentText.length <= 200) onChange(editor.getHTML())
    }
  })

  if (!editor) {
    return null
  }

  useEffect(() => {
    if (editor.getText()) {
      setUrl(extractLastLinkFromHTML(value) || '')
    }
  }, [])

  useEffect(() => {
    form.setFieldsValue({url})
  }, [url])

  useEffect(() => {
    if (editor && editor.getHTML() !== value) {
      editor.commands.setContent(value, false)
    }
  }, [value, editor])

  const extractLastLinkFromHTML = (html: string): string => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')
    const links = doc.querySelectorAll('a')

    if (links.length === 0) return ''

    const lastLink = links[links.length - 1]

    return lastLink.getAttribute('href') || ''
  }

  const getSelectedText = () => {
    const { state } = editor
    const { from, to } = state.selection

    return state.doc.textBetween(from, to, ' ')
  }

  const getLinkFromText = (text) => {
    let linkInfo = null

    editor.state.doc.descendants((node, pos) => {
      if (node.isText && node.text.includes(text)) {
        node.marks.forEach((mark) => {
          if (mark.type.name === 'link') {
            const start = pos + node.text.indexOf(text)
            const end = start + text.length

            linkInfo = {
              href: mark.attrs.href,
              text,
              from: start,
              to: end,
            }
          }
        })
      }
    })

    return linkInfo
  }

  const onBoldBtnClick = () => {
    editor.chain().focus().toggleBold().run()
  }

  const onItalicBtnClick = () => {
    editor.chain().focus().toggleItalic().run()
  }

  const onUnderlineBtnClick = () => {
    editor.commands.toggleUnderline()
  }

  const onLinkBtnClick = () => {
    const selectedText = getSelectedText()

    const link = getLinkFromText(selectedText)
    if (link)  {
      setUrl(link.href)
    }

    setPopover(true)
  }

  const handleFormSubmit = () => {
    if (!editor) return // Ensure editor is defined

    const trimmedUrl = (url ?? '').trim()

    if (trimmedUrl) {
      editor.chain().focus().extendMarkRange('link').setLink({ href: trimmedUrl }).run()
    } else {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()
    }

    setPopover(false)
  }

  return (
    <Card
      size='small'
      title={
        <Flex>
          <Tooltip title={translations().bold}>
            <Button
              icon={<BoldOutlined />}
              type='text'
              onClick={onBoldBtnClick}
            />
          </Tooltip>
          <Tooltip title={translations().italic}>
            <Button
              icon={<ItalicOutlined />}
              type='text'
              onClick={onItalicBtnClick}
            />
          </Tooltip>
          <Tooltip title={translations().underline}>
            <Button
              icon={<UnderlineOutlined />}
              type='text'
              onClick={onUnderlineBtnClick}
            />
          </Tooltip>
          <Tooltip title={translations().link}>
            <Button
              icon={<LinkOutlined />}
              type='text'
              onClick={onLinkBtnClick}
            />
          </Tooltip>
          <Popover
            open={isPopoverOpened}
            content={
              <Card size='small'>
                <Form
                  initialValues={{url}}
                  form={form}
                  onFinish={handleFormSubmit}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Flex gap='small'>
                    <Form.Item
                      name='url'
                      validateTrigger='onBlur'
                      rules={[
                        {
                          pattern: new RegExp(/^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/),
                          message: translations().enterValidUrl,
                        },
                      ]}
                    >
                      <Input
                        ref={inputRef}
                        placeholder={translations().validUrl}
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType='submit' type='primary'>
                        {translations().ok}
                      </Button>
                    </Form.Item>
                  </Flex>
                </Form>
              </Card>
            }
          >
          </Popover>
        </Flex>
      }
    >
      <div className={styles.editorWrapper}>
        <EditorContent editor={editor} />
      </div>
    </Card>
  )
}