export const INITIAL_FILTER_DATA = {
  page: 1,
  size: 10,
  title: null,
  description: null,
  notificationTypeId: null,
  notificationDisplayTypeId: null,
  systemId: null,
  statusId: null
}

export const mpColors = {
  primary: '#facf1a',
  secondary: '#bbbbbb'
}

export const dashColors = {
  primary: '#140c33',
  secondary: '#bbbbbb'
}