import React from 'react'
import { CircleProps } from '~/code/components/NotificationsManagement/Preview/shared/Circle/props'
import styles from './styles.scss'

export const Circle = ({diameter, color}: CircleProps) => {
  const circleStyles = {
    width: diameter,
    height: diameter,
    backgroundColor: color
  }

  return (
    <div className={styles.circle} style={circleStyles}></div>
  )
}