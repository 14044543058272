import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    newNotification: string
    notificationDetails: string
    viewDetails: string
    republish: string
    deactivate: string
    template: string
    system: string
    type: string
    status: string
    endDate: string
    startDate: string
    actions: string
    maintenanceIncident: string
    push: string
    marketing: string
    description: string
    title: string
    view: string
    select: string
    firstName: string
    lastName: string
    email: string
    descriptor: string
    companyName: string
    publish: string
    manageNotifications: string
    refresh: string
    deactivateTitle: string
    deactivateContent: string
    displayType: string
    contentArea: string
    userType: string
    partner: string
    employee: string
}>()

