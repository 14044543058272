import React from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { PageHeaderRaw } from '~/code/components'
import { Button, Card, Col, Row, Space, Steps, Typography } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { INotificationsManagementStore, NotificationsManagementStoreSymbol } from '~/code/pages/Notifications'
import {
  PreviewStep,
  SelectTemplateStep
} from '~/code/pages/Notifications/ManageNotifications/pages/PublishNotification/components'
import { ITEMS } from './constants'
import translations from './translations'



export const PublishNotification = observer(() => {
  const store = useInjection<INotificationsManagementStore>(NotificationsManagementStoreSymbol)
  const {step, setStep} = store

  const steps = [<SelectTemplateStep />, <PreviewStep />]

  const onBackBtnClick = () => {
    step === 0 ? goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_NOTIFICATIONS) : setStep(step - 1)
  }

  return (
    <>
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        <PageHeaderRaw>
          <Space direction={'vertical'} size={'small'}>
            <Row>
              <Button
                onClick={onBackBtnClick}
                icon={<LeftOutlined />}
                type={'link'}
                size={'small'}
                style={{paddingLeft: 0}}
              >
                {translations().back}
              </Button>
            </Row>
            <Row>
              <Col>
                <Typography.Title level={5}>{translations().publishNotification}</Typography.Title>
              </Col>
            </Row>
          </Space>
        </PageHeaderRaw>

        <Card>
          <Steps current={step} items={ITEMS}/>
        </Card>
        {steps[step]}
      </Space>
    </>
  )
})