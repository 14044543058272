import React, {createContext} from 'react'
import { TemplateType } from '~/code/stores/NotificationManagementStore'
import { TemplatePreviewConfigType } from '~/code/stores/NotificationManagementStore/models/TemplatePreviewConfigType'


export type TemplateConfigProviderType = {
  templateForm: TemplateType
  previewConfig: TemplatePreviewConfigType
}

export const TemplateConfigContext = createContext<TemplateConfigProviderType | null>(null)

export const TemplateConfigProvider: React.FC<{ value: TemplateConfigProviderType, children: React.ReactNode }> = ({value, children, }) => {
  return (
    <TemplateConfigContext.Provider value={value}>
      {children}
    </TemplateConfigContext.Provider>
  )
}