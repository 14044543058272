import { action, makeObservable, observable } from 'mobx'
import { injectable } from 'inversify'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { RealTimeTransactionDataStoreInterface } from './RealTimeTransactionDataStoreInterface'
import {
  RealTimeTransactionModel,
  RealTimeTPSModel,
  RealTimeTPSHistoryType
} from '~/code/stores/RealTimeTransactionStore/models'
import { transformToChartData } from './utils'

export const RealTimeTransactionDataStoreSymbol = Symbol('RealTimeTransactionDataStoreSymbol')

@injectable()
export class RealTimeTransactionDataStore implements RealTimeTransactionDataStoreInterface {
  public data: RealTimeTransactionModel = null
  public tpsData: RealTimeTPSModel = null
  public tpsHistoryData: RealTimeTPSHistoryType = null

  constructor() {
    makeObservable(this, {
      data: observable,
      tpsData: observable,
      tpsHistoryData: observable,

      updateData: action.bound
    })
  }

  public updateData = (newData: RealTimeTransactionModel) => {
    if (
      (hasPermissions([PermissionMap.transactions.dna_ecom_realtime.read]) && newData.source === 'ecom') ||
      (hasPermissions([PermissionMap.transactions.dna_pos_realtime.read]) && newData.source === 'pos')
    ) {
      this.data = newData
    }
  }

  public updateTPSData = (newData: RealTimeTPSModel) => {
    this.updateCurrentTPSData(newData)
    this.updateHistoryTPSData(newData)
  }

  public updateCurrentTPSData = (newData: RealTimeTPSModel) => {
    if (this.tpsData?.percentageTps === newData?.percentageTps) return

    this.tpsData = newData
  }

  public updateHistoryTPSData = (newData: RealTimeTPSModel) => {
    this.tpsHistoryData = transformToChartData(newData?.data ?? [])
  }
}
