import { Col, Form, FormInstance, Input as AntInput, Row, Input } from 'antd'
import React from 'react'
import translations from '../translations'
import { advancePaymentsData, processingMethodsData } from '../constants/data'
import {
  getSum,
  resetAdvancePaymentsError,
  resetProcessingMethodsError,
  setAdvancePaymentsError,
  setProcessingMethodsError
} from './utils'
import styles from '../FinancialInfo.scss'
import { isEmpty } from 'dna-common'

export const renderProcessingMethodBlock = (
  tForm: FormInstance,
  getTotal: (string) => number,
  isMoto: boolean,
  isVT: boolean
) => {
  return processingMethodsData.map(method => (
    <Row key={method.key}>
      <Col>
        <Form.Item
          key={method.key}
          name={[`processingMethods`, method.key, 'percent']}
          required={getSum('processingMethods', tForm) !== 100}
          rules={[
            {
              validator: async (_, rawValue) => {
                if (isMoto && method.key === 'moto' && (isEmpty(rawValue) || rawValue < 1)) {
                  return Promise.reject(new Error(translations().motoError))
                }

                if (isVT && method.key === 'virtualTerminal' && (isEmpty(rawValue) || rawValue < 1)) {
                  return Promise.reject(new Error(translations().vtError))
                }

                if (rawValue < 0 || rawValue > 100) {
                  return Promise.reject(new Error(translations().range))
                } else if (getSum('processingMethods', tForm) !== 100) {
                  setProcessingMethodsError(tForm)
                } else {
                  resetProcessingMethodsError(tForm)
                  return Promise.resolve()
                }
              }
            }
          ]}
        >
          <Input
            type='number'
            className={styles.styledInput}
            addonAfter='%'
            onChange={() => getTotal('processingMethods')}
          />
        </Form.Item>
      </Col>
      <Col>{method.description}</Col>
    </Row>
  ))
}

export const renderAdvancePaymentsBlock = (tForm: FormInstance, getTotal: (string) => number) => {
  const renderDescriptionField = (payment, isRequired) => (
    <Form.Item
      name={['advancePayments', payment.key, 'description']}
      rules={[{ required: isRequired, message: translations().enterDetails }]}
    >
      <Input type='number' placeholder={translations().enterDetails} />
    </Form.Item>
  )

  return advancePaymentsData.map((payment, id) => (
    <Form.Item key={id} required={payment.key !== 'other'}>
      <AntInput.Group>
        <Row gutter={10}>
          <Col span={5}>
            <Form.Item
              key={payment.description}
              name={['advancePayments', payment.key, 'percent']}
              rules={[
                { required: getSum('advancePayments', tForm) !== 100, message: translations().required },
                {
                  validator: async (_, rawValue) => {
                    if (rawValue < 0 || rawValue > 100) {
                      return Promise.reject(new Error(translations().range))
                    } else if (getSum('advancePayments', tForm) !== 100) {
                      setAdvancePaymentsError(tForm)
                    } else {
                      resetAdvancePaymentsError(tForm)
                      return Promise.resolve()
                    }
                  }
                }
              ]}
            >
              <Input
                type='number'
                className={styles.styledInput}
                addonAfter='%'
                onChange={() => getTotal('advancePayments')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            {payment.hasInput ? (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return prevValues.advancePayments?.other?.percent !== currentValues.advancePayments?.other?.percent
                }}
              >
                {() => {
                  const isRequired = Number(tForm.getFieldValue(['advancePayments', payment.key, 'percent'])) > 0
                  return renderDescriptionField(payment, isRequired)
                }}
              </Form.Item>
            ) : (
              payment.description
            )}
          </Col>
          <Col span={8}>
            <Form.Item
              name={['advancePayments', payment.key, 'days']}
              key={`${payment.key}Days`}
              rules={[
                {
                  required: Number(tForm.getFieldValue(['advancePayments', payment.key, 'percent'])) > 0,
                  message: translations().required
                },
                {
                  pattern: /^\d+(\.\d{1,2})?$/,
                  message: translations().onlyPositive
                }
              ]}
            >
              <Input type='number' className={styles.styledInput} addonAfter='Days' />
            </Form.Item>
          </Col>
        </Row>
      </AntInput.Group>
    </Form.Item>
  ))
}
