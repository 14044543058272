import React from 'react'
import classNames from 'classnames'
import { notificationDescription } from '~/code/services'
import {
  TemplateConfigProviderType
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components'
import styles from './styles.scss'

export const FullBlockMockup = ({isMobile = false, templateConfig}: {isMobile?: boolean, templateConfig: TemplateConfigProviderType}) => {
  const {templateForm } = templateConfig
  const {title, descriptionLine1} = templateForm

  return (
    <div className={styles.contentWrapper}>
      <div className={classNames( {[styles.mobileContent] : isMobile}, {[styles.content]: !isMobile}) }>
        <div className={classNames(styles.notification, {[styles.mobileAlign] : isMobile})}>
          <div className={styles.logo} />
          <div className={styles.title}>
            {title}
          </div>
          <div
            className={classNames(styles.description, )}
            dangerouslySetInnerHTML={{ __html: notificationDescription(descriptionLine1) }}
          ></div>
        </div>
        <div className={classNames(styles.image, {[styles.mobileAlign] : isMobile})}></div>
      </div>
    </div>
  )
}