import React from 'react'
import { Col, Form, Input, Row, Select } from 'antd'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'
import { ITemplatesManagementStore, TemplatesManagementStoreSymbol } from '~/code/pages/Notifications'
import { NotificationDisplayTypeEnum } from '~/code/models'
import translations from './translations'

export const SelectTemplateStepTableFilter = observer(() => {
  const store = useInjection<ITemplatesManagementStore>(TemplatesManagementStoreSymbol)

  const {setFilter, loadTemplates} = store
  const screens = useBreakpoint()

  return (
    <>
      <Form
        layout={screens.sm ? 'horizontal' : 'vertical'}
        labelAlign={'left'}
      >
        <Row gutter={24}>
          <Col sm={6}>
            <Form.Item name={'title'} label={translations().title}>
              <Input
                onChange={(event) => setFilter({ title: event.target.value })}
                onBlur={loadTemplates}
              />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item
              name='notificationDisplayTypeId'
              label={translations().displayType}
            >
              <Select
                defaultValue={'All'}
                onChange={value => setFilter({ notificationDisplayTypeId: value }, true)}
              >
                <Select.Option value={'all'}>{translations().all}</Select.Option>
                <Select.Option value={NotificationDisplayTypeEnum.POPUP}>{translations().popup}</Select.Option>
                <Select.Option value={NotificationDisplayTypeEnum.BANNER}>{translations().banner}</Select.Option>
                <Select.Option value={NotificationDisplayTypeEnum.BLOCK}>{translations().block}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item name={'systemId'} label={translations().system}>
              <Select
                onChange={(value) => setFilter({ systemId: value }, true)}
                defaultValue={'all'}
              >
                <Select.Option value={'all'}>{translations().all}</Select.Option>
                <Select.Option value={'dashboard'}>{translations().dashboard}</Select.Option>
                <Select.Option value={'mp'}>{translations().merchantPortal}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item name={'notificationTypeId'} label={translations().type}>
              <Select
                onChange={(value) => setFilter({ notificationTypeId: value }, true)}
                defaultValue={'All'}
              >
                <Select.Option value={'all'}>{translations().all}</Select.Option>
                <Select.Option value={'maintenance_incident'}>{translations().maintenance}</Select.Option>
                <Select.Option value={'push'}>{translations().push}</Select.Option>
                <Select.Option value={'marketing'}>{translations().marketing}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
})