import React from 'react'
import { TableHeader } from '~/code/components/NotificationsManagement/Preview/shared/TableHeader'
import { TableRow } from '~/code/components/NotificationsManagement/Preview/shared/TableRow'
import {
  FullBlockMockup
} from '~/code/components/NotificationsManagement/FullBlockMockup/FullBlockMockup'
import { notificationDescription } from '~/code/services'
import { NotificationDisplayTypeEnum } from '~/code/models'
import {
  TemplateConfigProviderType
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components'
import styles from './styles.scss'

export const PreviewContent = ({isMobile = false, templateConfig}: {isMobile?: boolean, templateConfig: TemplateConfigProviderType}) => {
  const {templateForm, previewConfig} = templateConfig
  const {notificationLevelId, title, descriptionLine1, notificationContentAreaId, notificationDisplayTypeId} = templateForm

  const renderTable = () => (
    Array.from({ length: 10 }, (_, index) => (
      index === 0 || index === 5 ? (
        <TableHeader key={index} />
      ) : (
        <TableRow key={index} />
      )
    ))
  )


  return (
    <div className={styles.container}>
      {notificationLevelId && notificationDisplayTypeId !== NotificationDisplayTypeEnum.POPUP && (
        <div className={styles.banner} style={{...previewConfig.banner}}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description} dangerouslySetInnerHTML={{__html: notificationDescription(descriptionLine1)}}></div>
        </div>
      )}
      <div className={styles.tableContainer}>
        { notificationDisplayTypeId === NotificationDisplayTypeEnum.BLOCK && notificationContentAreaId !== 'app' ? (
          <FullBlockMockup isMobile={isMobile} templateConfig={templateConfig}/>
        ) : renderTable()}
      </div>
    </div>
  )
}