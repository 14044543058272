import React from 'react'
import classNames from 'classnames'
import { useTemplateConfig } from '~/code/hooks'
import { PreviewContent, PreviewHeader, PreviewSider } from '~/code/components/NotificationsManagement'
import styles from './styles.scss'
export const SectionMockup = ({isMobile = false}: {isMobile?: boolean}) => {
  const config = useTemplateConfig()

  return(
    <div className={classNames({[styles.templateContainer]: isMobile})}>
      <PreviewHeader templateConfig={config}/>
      <div className={styles.contentContainer}>
        {!isMobile ? (
          <PreviewSider templateConfig={config}/>
        ): null}
        <PreviewContent isMobile={isMobile} templateConfig={config}/>
      </div>
    </div>
  )
}