import React from 'react'
import { Col, DatePicker, Form, Row } from 'antd'
import { NamePath } from 'antd/lib/form/interface'
import moment from 'moment/moment'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'
import { INotificationsManagementStore, NotificationsManagementStoreSymbol } from '~/code/pages/Notifications'
import translations from './translations'

export const PreviewStepTableFilter = observer(({ form }) => {
  const store = useInjection<INotificationsManagementStore>(NotificationsManagementStoreSymbol)
  const { setPublishForm } = store

  const screens = useBreakpoint()

  const disabledDate = (current) => {
    return current && current.isBefore(moment().startOf('day'), 'day')
  }

  const disabledTime = (current) => {
    if (!current || !current.isSame(moment(), 'day')) {
      return {}
    }

    const now = moment()
    return {
      disabledHours: () => Array.from({ length: now.hour() }, (_, i) => i),
      disabledMinutes: (selectedHour) => {
        if (selectedHour === now.hour()) {
          return Array.from({ length: now.minute() }, (_, i) => i)
        }
        return []
      },
    }
  }

  return (
    <>
      <Form
        layout={screens.sm ? 'horizontal' : 'vertical'}
        labelAlign={'left'}
        form={form}
      >
        <Row gutter={24}>
          <Col sm={12}>
            <Form.Item
              name='startDate'
              label={translations().startDate}
              validateTrigger={'onChange'}
              dependencies={['endDate'] as NamePath[]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const endDate = getFieldValue(['endDate'])
                    if (endDate && value) {
                      if (value.isSame(endDate)) {
                        return Promise.reject(new Error('Start date and end date cannot be the same'))
                      }
                      if (value.isAfter(endDate)) {
                        return Promise.reject(new Error('Start date must be earlier than end date'))
                      }
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <DatePicker
                showTime
                showSecond={false}
                onChange={(date, dateString) => setPublishForm({startDate: dateString})}
                style={{ width: '100%' }}
                disabledDate={disabledDate}
                disabledTime={disabledTime}
                showNow={false}
              />
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item
              name='endDate'
              label={translations().endDate}
              dependencies={['startDate'] as NamePath[]}
              validateTrigger={'onChange'}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const startDate = getFieldValue(['startDate'])
                    if (startDate && value) {
                      if (value.isSame(startDate)) {
                        return Promise.reject(new Error('End date must be different from start date'))
                      }
                      if (value.isBefore(startDate)) {
                        return Promise.reject(new Error('End date must be later than start date'))
                      }
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <DatePicker
                showTime
                showSecond={false}
                onChange={(date, dateString) => setPublishForm({endDate: dateString})}
                style={{ width: '100%' }}
                disabledDate={disabledDate}
                disabledTime={disabledTime}
                showNow={false}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
})