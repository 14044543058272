import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  title: string
  edit: string
  cancel: string
  submit: string
  invite: string
  isDefaultTooltip: string
  shopPlaceholder: string
  specificPermissions: string
  formFields: {
    email: string
    store: string
    firstName: string
    lastName: string
    teammatePermissions: string
    inviteAsAdmin: string
    inviteAsRestrictedAccess: string
    accountantAccess: string
    customAccess: string
    grantedPermissions: string
    grantedSpecificPermissions: string
    accessLevels: string
    sectionName: string
    noAccess: string
    readAccess: string
    fullAccess: string
    sendWelcomeEmail: string
    isDefault: string
    merchant: string
    sections: {
      overview: string
      online_payments: string
      pos_payments: string
      payment_links: string
      virtual_terminal: string
      zash_epos: string
      settlements: string
      reports: string
      payment_methods: string
      teammates: string
      invoices: string
      pos_amex_payments: string
      chargebacks: string
      card_issuing: string
      'merchant.profile': string
      'merchant.bank_accounts': string
      'merchant.pos_stores': string
      'merchant.ecom_stores': string
    }
  }
  virtual_terminal_refunds: string
  virtualTerminalRefundsHint: string
  formErrors: {
    emailRequired: string
    shopRequired: string
    enterValidEmail: string
    firstNameRequired: string
    lastNameRequired: string
    merchantRequired: string
    permissionRequired: string
  }
}>()
