import React from 'react'
import { observer } from 'mobx-react'
import { Col, Row } from 'antd'
import { useInjection } from 'dna-react-ioc'
import {
  RealTimeTransactionDataStoreSymbol,
  RealTimeTransactionDataStoreInterface
} from '~/code/stores/RealTimeTransactionStore/RealTimeTransactionDataStore'
import { RealTimeTransactionMap, LiquidChart, ColumnChart } from '~/code/pages/RealTimeTransaction/components'

import styles from './styles.scss'

export const RealTimeTransaction = observer(() => {
  const { tpsData, tpsHistoryData } = useInjection<RealTimeTransactionDataStoreInterface>(
    RealTimeTransactionDataStoreSymbol
  )

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} sm={24} lg={24} xl={24}>
        <RealTimeTransactionMap className={styles.transactionsMap} />
      </Col>

      <Col xs={24} sm={24} lg={24} xl={24}>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={8} lg={8} xl={8}>
            <LiquidChart data={tpsData} />
          </Col>

          <Col xs={24} sm={16} lg={16} xl={16}>
            <ColumnChart data={tpsHistoryData} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
})
