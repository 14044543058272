import React from 'react'
import {
  SectionMockup
} from '~/code/components/NotificationsManagement/SectionMockup/SectionMockup'
import styles
  from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplatePreview/components/WebPreview/components/PopupPreview/styles.scss'

export const BannerMobilePreview = () => {
  return (
    <div className={styles.contentContainer}>
      <SectionMockup isMobile={true}/>
    </div>
  )
}