import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Layout, Grid } from 'antd'
import { Footer } from '~/code/components/layout/Footer'
import { Header } from '~/code/components/layout/Header'
import { Sider } from '~/code/components/layout/Sider'
import { SiderMenu } from '~/code/components/layout/SiderMenu';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { useAuthNotificationStore } from '~/code/stores/notification'
import { useStorageNotification } from '~/code/hooks'
import { BannerNotification, BlockNotification, PopupNotification } from '~/code/components/Notifications'
import { NotificationStatusEnum } from '~/code/stores/notification/types'
import { NotificationDisplayTypeEnum } from '~/code/models'
import { MainLayoutProps } from './props'
import styles from './styles.scss'

const { useBreakpoint } = Grid

export const AuthNotificationStoreSymbol = Symbol('AuthNotificationStoreSymbol')

export const MainLayout = observer((props: MainLayoutProps) =>  {
  const { store, currentRoute, children } = props
  const [ isCollapsed, setCollapsed ] = useState(false)
  const {sections: sectionsNotifications, getCurrentNotification, hasRoutePermission} = useAuthNotificationStore()

  const hasPermission = hasRoutePermission(store.menuData, currentRoute)

  // get current section notification based on the current route
  // and check if it is already closed in the local storage
  const currentSectionNotification = getCurrentNotification(currentRoute)

  const {isClosed: isSectionNotificationClosed, onCloseNotification: onCloseSectionNotification} =
    useStorageNotification(currentSectionNotification,'auth', store.userEmail)

  // get popup notification
  // and check if it is already closed in the local storage
  const popupNotification = sectionsNotifications['auth']
  const {isClosed: isPopupNotificationClosed, onCloseNotification: onClosePopupNotification} =
    useStorageNotification(popupNotification,'auth', store.userEmail)


  const screens = useBreakpoint()
  const isMobile = screens.xs || !screens.md

  useEffect(() => {
      if (screens.xs || screens.lg === false) {
          setCollapsed(true)
      }
  }, [screens])

  const showBanner = () => {
      return hasPermission && currentSectionNotification
        && currentSectionNotification.notificationDisplayTypeId === NotificationDisplayTypeEnum.BANNER
        && !isSectionNotificationClosed
  }

  const showBlock = () => {
      return hasPermission && currentSectionNotification
        && currentSectionNotification.notificationDisplayTypeId === NotificationDisplayTypeEnum.BLOCK
        && currentSectionNotification.statusId === NotificationStatusEnum.ACTIVE
  }

  return (
    <Layout hasSider className={styles.root}>
      <Sider isMobile={isMobile} className={styles.sider} collapsed={isCollapsed} onCollapse={c => setCollapsed(c)}>
        <SiderMenu
          menuData={store.menuData}
          onSelect={key => {
            store.onMenuItemClick(key)
            if (isMobile) {
              setCollapsed(true)
            }
          }}
        />
      </Sider>
      <div className={styles.container}>
        <Header currentRoute={currentRoute} className={styles.header} isMobile={isMobile}>
          {isMobile && (
            <div className={styles.siderTrigger} onClick={() => setCollapsed(!isCollapsed)}>
              {isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </div>
          )}
        </Header>

        <Layout.Content className={styles.content}>
          { showBanner()
              ? (<div className={styles.bannerWrapper}>
                  <BannerNotification notification={currentSectionNotification} onCloseNotification={onCloseSectionNotification}/>
                </div>)
              : null}
          { showBlock()
              ? (<BlockNotification notification={currentSectionNotification}/>)
              : children}
        </Layout.Content>
        {popupNotification && !isPopupNotificationClosed &&
          <PopupNotification notification={popupNotification} onCloseNotification={onClosePopupNotification} />}
        <Footer />
      </div>
    </Layout>
  )
})
