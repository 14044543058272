import { message } from 'antd'
import {
  TemplateType
} from '~/code/stores/NotificationManagementStore'
import { TemplatePreviewConfigType } from '~/code/stores/NotificationManagementStore/models/TemplatePreviewConfigType'
import { NotificationDisplayTypeEnum } from '~/code/models'

export const isClosableCompute = (templateForm: TemplateType) => {
  const {notificationDisplayTypeId, notificationContentAreaId} = templateForm

  switch(notificationDisplayTypeId) {
    case NotificationDisplayTypeEnum.BANNER:
      return notificationContentAreaId !== 'auth' && notificationContentAreaId !== 'login'
    case NotificationDisplayTypeEnum.POPUP:
      return true
    default: return false
  }
}

export const handleFulfilled = <T>(result: PromiseSettledResult<T>) => {
  if (result.status === 'fulfilled' && result.value) {
    const { value } = result
    const { error, result: data } = value as any

    if (error) {
      message.error(error.message)
      return null
    }
    return data || null
  }
  return null
}

export const removeHtmlTags = (inputString: any): string => {
  if (!inputString) return '' // Handle falsy values like null and undefined
  return String(inputString).replace(/<\/?[^>]+(>|$)/g, '') // Convert to string before replacing
}

export const formatDataToSubmit = (templateForm: TemplateType) => {
  const {title, descriptionLine1,isClosable, ...rest} = templateForm
  const isClosableComputed = isClosableCompute(templateForm)

  return {
    title: title.trim(),
    descriptionLine1: descriptionLine1.trim(),
    isClosable: isClosableComputed,
    ...rest
  }
}

export const formatToConfig = (data: TemplateType) => {
  const {systemId, notificationLevelId} = data
  const styleConfig: TemplatePreviewConfigType = {
    banner: {
      backgroundColor: '',
      color: ''
    },
    header: {
      backgroundColor: ''
    },
    sider: {
      backgroundColor: ''
    },
    primaryButton: {
      backgroundColor: '#1677FF',
      color: '#fff'
    }
  }

  const colors = {
    error: '#ea6262',
    warning: '#facf1a',
    info: '#c7ddff',
    success: '#69b6aa'
  }

  const fontColors = {
    error: '#fff',
    warning: '#000',
    info: '#3d5f92',
    success: '#fff'
  }

  switch(systemId) {
    case('mp'):
      styleConfig.banner.backgroundColor = notificationLevelId ? colors[notificationLevelId] : null
      styleConfig.banner.color = notificationLevelId ? fontColors[notificationLevelId] : null
      styleConfig.header.backgroundColor = '#fff'
      styleConfig.sider.backgroundColor = '#fff'
      styleConfig.primaryButton = {
        backgroundColor: '#facf1a',
        color: '#000'
      }
      break
    default:
      styleConfig.banner.backgroundColor =  notificationLevelId ? colors[notificationLevelId] : null
      styleConfig.header.backgroundColor = '#140c33'
      styleConfig.sider.backgroundColor = '#140c33'
  }

  return styleConfig
}