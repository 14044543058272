import React from 'react'
import { BarProps } from '~/code/components/NotificationsManagement/Preview/shared/Bar/props'
import styles from './styles.scss'

export const Bar = ({color, ...rest}: BarProps) => {
  const barStyle = {
    backgroundColor: color,
    ...rest
  }

  return (
    <div className={styles.bar}>
      <div style={barStyle}/>
    </div>
  )
}