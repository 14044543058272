import { action, makeObservable, observable } from 'mobx'
import { message } from 'antd'
import { ApiResponse } from 'back-connector'
import {
  NotificationsFilterType
} from '~/code/stores/NotificationManagementStore/models/NotificationsFilterType'
import { INITIAL_FILTER_DATA } from '~/code/stores/NotificationManagementStore/constants'
import { INotificationsDataStore } from '~/code/stores/NotificationManagementStore/models'


type FetchDataType<T> = (params: NotificationsFilterType) => Promise<ApiResponse<T>>


export class NotificationsDataStore<T extends {data: R[], totalCount: number}, R> implements INotificationsDataStore<T, R> {
  filter: NotificationsFilterType = INITIAL_FILTER_DATA
  isLoading = false
  isDrawerOpened = false
  isApproveModalOpened = false
  totalCount: number = 0
  data: R[] = []
  constructor(public fetchData: FetchDataType<T>) {
    makeObservable(this, {
      filter: observable,
      isLoading: observable,
      isDrawerOpened: observable,
      isApproveModalOpened: observable,
      totalCount: observable,
      data: observable,
      setFilter: action.bound,
      resetFilter: action.bound,
      setDrawer: action.bound
    })
  }

  public setFilter = (values: Partial<NotificationsFilterType>, shouldLoad: boolean = false) => {
    const normalizedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] = values[key] === 'all' ? undefined : values[key]
      return acc
    }, {} as Record<string, any>)

    this.filter = {
      ...this.filter,
      ...normalizedValues,
    }

    if (shouldLoad) this.loadData()
  }

  public resetFilter = () => {
    this.filter = INITIAL_FILTER_DATA
    this.loadData()
  }

  public setDrawer = (value: boolean) => {
    this.isDrawerOpened = value
  }

  public setApproveModalOpen = (value: boolean) => {
   this.isApproveModalOpened = value
  }

  public async loadData() {
    this.isLoading = true
    try {
      const {result, error} = await this.fetchData(this.filter)
      if (error) throw new Error(error.message)
      this.data = result.data
      this.totalCount = result.totalCount
    } catch(error) {
      message.error(error.message)
    } finally {
      this.isLoading = false
    }
  }
}