import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  enterValidUrl: string
  validUrl: string
  ok: string
  link: string
  underline: string
  italic: string
  bold: string
}>()

