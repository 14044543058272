import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Form, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FormColumnModel } from '~/code/components/FormSearch/models'
import { FormSearch, Input } from '~/code/components'
import { NotificationDisplayTypeEnum } from '~/code/models'
import { ITemplatesManagementStore, TemplatesManagementStoreSymbol } from '~/code/pages/Notifications'
import translations from './translations'

export const NotificationTemplatesFilter = observer(() => {
  const {
    filter,
    setFilter,
    loadTemplates,
    resetFilter
  } = useInjection<ITemplatesManagementStore>(TemplatesManagementStoreSymbol)

  useEffect(() => {
    return () => resetFilter()
  }, [])

  const [ form ] = useForm()

  const onReset = () => {
    form.resetFields()
    resetFilter()
  }

  const items: FormColumnModel[]= [
    {
      key: 'type',
      value: filter.notificationTypeId,
      colProps: { xs: 24, md: 8, lg: 6 },
      collapsedColProps: { xs: 24, sm: 15, md: 8, lg: 6 },
      component: <Form.Item
        name='notificationTypeId'
        label={translations().type}
      >
        <Select
          defaultValue={'All'}
          onChange={value => setFilter({ notificationTypeId: value }, true)}
        >
          <Select.Option value={'all'}>{translations().all}</Select.Option>
          <Select.Option value={'maintenance_incident'}>{translations().maintenanceIncident}</Select.Option>
          <Select.Option value={'push'}>{translations().push}</Select.Option>
          <Select.Option value={'marketing'}>{translations().marketing}</Select.Option>
        </Select>
      </Form.Item>
    },
    {
      key: 'system',
      value: filter.systemId,
      colProps: { xs: 24, md: 8, lg: 6 },
      collapsedColProps: { xs: 24, sm: 15, md: 8, lg: 6 },
      component: <Form.Item
        name='systemId'
        label={translations().system}
      >
        <Select
          defaultValue={'All'}
          onChange={value => setFilter({ systemId: value }, true)}
        >
          <Select.Option value={'all'}>{translations().all}</Select.Option>
          <Select.Option value={'mp'}>{translations().merchantPortal}</Select.Option>
          <Select.Option value={'dashboard'}>{translations().dashboard}</Select.Option>
          <Select.Option value={'bpm'}>{translations().bpm}</Select.Option>
        </Select>
      </Form.Item>
    },
    {
      key: 'notificationDisplayTypeId',
      value: filter.systemId,
      colProps: { xs: 24, md: 8, lg: 6 },
      collapsedColProps: { xs: 24, sm: 15, md: 8, lg: 6 },
      component: <Form.Item
        name='notificationDisplayTypeId'
        label={translations().displayType}
      >
        <Select
          defaultValue={'All'}
          onChange={value => setFilter({ notificationDisplayTypeId: value }, true)}
        >
          <Select.Option value={'all'}>{translations().all}</Select.Option>
          <Select.Option value={NotificationDisplayTypeEnum.POPUP}>{translations().popup}</Select.Option>
          <Select.Option value={NotificationDisplayTypeEnum.BANNER}>{translations().banner}</Select.Option>
          <Select.Option value={NotificationDisplayTypeEnum.BLOCK}>{translations().block}</Select.Option>
        </Select>
      </Form.Item>
    },
    {
      key: 'title',
      value: filter.title,
      colProps: { xs: 24, md: 8, lg: 6 },
      collapsedColProps: { xs: 24, sm: 15, md: 8, lg: 6 },
      component: <Form.Item
        name='title'
        label={translations().title}
      >
        <Input
          onChange={event => setFilter({ title: event.target.value })}
          onBlur={() => loadTemplates()}
        />
      </Form.Item>
    },
    {
      key: 'description',
      value: filter.description,
      colProps: { xs: 24, md: 8, lg: 6 },
        component: <Form.Item
        name='description'
        label={translations().description}
      >
        <Input
          onChange={(event) => setFilter({ description: event.target.value })}
          onBlur={() => loadTemplates()}
        />
      </Form.Item>
    },
  ]

  return (
    <>
      <FormSearch
        form={form}
        onReset={onReset}
        isCollapsible={true}
        items={items}
      />
    </>
  )
})