import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Button, Card, Col, Drawer, Pagination, Row, Space, Table, TablePaginationConfig } from 'antd'
import {
  SelectTemplateStepTableFilter
} from '~/code/pages/Notifications/ManageNotifications/pages/PublishNotification/components'
import { PlusOutlined } from '@ant-design/icons'
import {
  getColumns
} from '~/code/pages/Notifications/ManageNotifications/pages/PublishNotification'
import { useInjection } from 'dna-react-ioc'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { ReloadButton } from '~/code/components'
import {
  TemplatePreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplatePreview'
import {
  INotificationsManagementStore,
  ITemplatesManagementStore, NotificationsManagementStoreSymbol,
  TemplatesManagementStoreSymbol
} from '~/code/pages/Notifications'
import translations from './translations'
import styles from './styles.scss'

export const SelectTemplateStep = observer(() => {
  const {
    data,
    isLoading,
    isDrawerOpened,
    onDrawerClose,
    filter,
    totalCount,
    setFilter,
    templateForm,
    templatePreviewConfig,
    viewDetails,
    selectTemplate,
    newTemplate,
    loadTemplates
  } = useInjection<ITemplatesManagementStore>(TemplatesManagementStoreSymbol)
  const {setStep} = useInjection<INotificationsManagementStore>(NotificationsManagementStoreSymbol)

  useEffect(() => {
    loadTemplates()
  }, [])

  const selectTemplateById = async (id: string) => {
    selectTemplate(id).then(() => setStep(1))
  }

  const columnsMethods = {
    viewDetails,
    selectTemplateById
  }

  const columns = getColumns(columnsMethods)
  const screens = useBreakpoint()

  const paginationProps: TablePaginationConfig = generateTablePaginationProps(
    filter.page,
    filter.size,
    totalCount,
    (page: number, pageSize: number) => setFilter({page, size: pageSize}, true)
  )

  return (
    <>
      <Space direction="vertical" size={'middle'} style={{ display: 'flex' }}>
        <Card className={styles.filterCard}>
          <SelectTemplateStepTableFilter/>
        </Card>

        <Card>
          <Space direction="vertical" size={'middle'} style={{ display: 'flex' }}>
            <Row justify={'space-between'}>
              <Col>
                {translations().cantFind}
                <Button
                  className={styles.createTemplateBtn}
                  disabled={!hasPermissions([PermissionMap.notifications.templates.create])}
                  color="primary"
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={() => newTemplate()}
                  iconPosition={'start'}
                >
                  {translations().createNew}
                </Button>
              </Col>
              <Col>
                <Row gutter={4}>
                  <Col>
                    <ReloadButton onClick={() => setFilter(filter, true)}/>
                  </Col>
                  <Col>
                    <Pagination {...paginationProps} disabled={false} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Table
                  loading={isLoading}
                  dataSource={data}
                  columns={columns}
                  pagination={false}
                  rowKey={(data) => data.id}
                  scroll={{ x: 1000, y: 1000 }}
                />
              </Col>
            </Row>
            <Row gutter={4} justify={'end'}>
              <Col>
                <ReloadButton onClick={() => setFilter(filter, true)}/>
              </Col>
              <Col>
                <Pagination {...paginationProps} disabled={false} />
              </Col>
            </Row>
          </Space>
        </Card>
      </Space>
      <Drawer
        title={translations().templateDetails}
        placement='right'
        open={isDrawerOpened}
        onClose={onDrawerClose}
        width={screens.xs ? 360 : 620}
      >
        <TemplatePreview templateForm={templateForm} previewConfig={templatePreviewConfig}/>
      </Drawer>
    </>
  )
})


