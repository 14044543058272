import React from 'react'
import { Dropdown, TableProps } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import { TemplatesDataType } from '~/code/stores/NotificationManagementStore'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import translations from '../translations'
import { removeHtmlTags } from '~/code/stores/NotificationManagementStore/services'
import { ITemplatesManagementStore } from '~/code/pages/Notifications'

const getMenuItems = (record: { id: string }, store: ITemplatesManagementStore) => {
  const { id } = record;
  const {viewDetails, editTemplate, deleteActionHandler} = store

  const menuItemsConfig = [
    {
      key: '1',
      label: translations().viewDetails,
      action: () => viewDetails(id),
      hasPermission: hasPermissions([PermissionMap.notifications.templates.read])
    },
    {
      key: '2',
      label: translations().edit,
      action: () => editTemplate(id),
      hasPermission: hasPermissions([PermissionMap.notifications.templates.update]),
    },
    {
      key: '3',
      label: translations().delete,
      action: () => deleteActionHandler(id),
      hasPermission: hasPermissions([PermissionMap.notifications.templates.delete]),
    },
  ]

  const items = menuItemsConfig.map((item) => ({
    key: item.key,
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        onClick={(event) => {
          event.stopPropagation()
          item.action()
        }}
      >
        {item.label}
      </a>
    ),
    hasPermission: item.hasPermission,
  }))

  return items
    .filter((item) => item.hasPermission)
    .map(({ hasPermission, ...rest }) => rest)
}

export const getTemplatesColumns = (store: ITemplatesManagementStore): TableProps<TemplatesDataType>['columns'] => {
  return [
    {
      title: translations().title,
      dataIndex: 'title',
      key: 'name',
    },
    {
      title: translations().description,
      dataIndex: 'descriptionLine1',
      key: 'description',
      render: (description) => (<div style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}>
        { removeHtmlTags(description)}
      </div>)
    },
    {
      title: translations().system,
      dataIndex: 'system',
      key: 'system'
    },
    {
      title: translations().displayType,
      dataIndex: 'displayType',
      key: 'displayType',
    },
    {
      title: translations().type,
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: translations().createDate,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: (value, record) => {
        const items = getMenuItems(record, store)
        return (
          <Dropdown menu={{ items }}>
            <EllipsisOutlined/>
          </Dropdown>
        )
      }
    },
  ]
}

export const GREY = 'grey'
export const DARKBLUE = 'darkBlue'
export const WHITE = 'white'