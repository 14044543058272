import React, { useEffect } from 'react'
import { Form, Input, Select } from 'antd'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { useForm } from 'antd/lib/form/Form'
import { FormColumnModel } from '~/code/components/FormSearch/models'
import { FormSearch } from '~/code/components'
import { INotificationsManagementStore, NotificationsManagementStoreSymbol } from '~/code/pages/Notifications'
import { NotificationDisplayTypeEnum } from '~/code/models'
import translations from './translations'

export const ManageNotificationsFilter = observer(() => {
  const store = useInjection<INotificationsManagementStore>(NotificationsManagementStoreSymbol)
  const {filter, setFilter, resetFilter, loadNotifications} = store

  useEffect(() => {
    return () => resetFilter()
  }, [])

  const [ form ] = useForm()

  const onReset = () => {
    form.resetFields()
    resetFilter()
  }

  const items: FormColumnModel[]= [
    {
      key: 'type',
      value: filter.notificationTypeId,
      colProps: { xs: 24, md: 6 },
      collapsedColProps: { xs: 24, sm: 15, md: 6, xl: 6 },
      component: <Form.Item name={'notificationTypeId'} label={translations().type}>
        <Select
          onChange={(value) => setFilter({ notificationTypeId: value }, true)}
          defaultValue={'All'}
        >
          <Select.Option value={'all'}>{translations().all}</Select.Option>
          <Select.Option value={'maintenance_incident'}>{translations().maintenanceIncident}</Select.Option>
          <Select.Option value={'push'}>{translations().push}</Select.Option>
          <Select.Option value={'marketing'}>{translations().marketing}</Select.Option>
        </Select>
      </Form.Item>
    },
    {
      key: 'type',
      value: filter.notificationTypeId,
      colProps: { xs: 24, md: 8, lg: 6 },
      collapsedColProps: { xs: 24, sm: 15, md: 8, lg: 6},
      component: <Form.Item name={'systemId'} label={translations().system}>
        <Select
          onChange={(value) => setFilter({ systemId: value }, true)}
          defaultValue={'All'}
        >
          <Select.Option value={'all'}>{translations().all}</Select.Option>
          <Select.Option value={'mp'}>{translations().merchantPortal}</Select.Option>
          <Select.Option value={'dashboard'}>{translations().dashboard}</Select.Option>
          <Select.Option value={'bpm'}>{translations().bpm}</Select.Option>
        </Select>
      </Form.Item>
    },
    {
      key: 'notificationDisplayTypeId',
      value: filter.systemId,
      colProps: { xs: 24, md: 8, lg: 6 },
      collapsedColProps: { xs: 24, sm: 15, md: 8, lg: 6 },
      component: <Form.Item
        name='notificationDisplayTypeId'
        label={translations().displayType}
      >
        <Select
          defaultValue={'All'}
          onChange={value => setFilter({ notificationDisplayTypeId: value }, true)}
        >
          <Select.Option value={'all'}>{translations().all}</Select.Option>
          <Select.Option value={NotificationDisplayTypeEnum.POPUP}>{translations().popup}</Select.Option>
          <Select.Option value={NotificationDisplayTypeEnum.BANNER}>{translations().banner}</Select.Option>
          <Select.Option value={NotificationDisplayTypeEnum.BLOCK}>{translations().block}</Select.Option>
        </Select>
      </Form.Item>
    },
    {
      key: 'statusId',
      value: filter.statusId,
      colProps: { xs: 24, md: 8, lg: 6 },
      collapsedColProps: { xs: 24, sm: 15, md: 8, lg: 6 },
      component: <Form.Item name={'statusId'} label={translations().status}>
        <Select
          onChange={(value) => setFilter({ statusId: value }, true)}
          defaultValue={'All'}
        >
          <Select.Option value={'all'}>{translations().all}</Select.Option>
          <Select.Option value={'ACTIVE'}>{translations().active}</Select.Option>
          <Select.Option value={'INACTIVE'}>{translations().inactive}</Select.Option>
          <Select.Option value={'SCHEDULED'}>{translations().scheduled}</Select.Option>
        </Select>
      </Form.Item>
    },
    {
      key: 'title',
      value: filter.title,
      colProps: { xs: 24, md: 6 },
      component: <Form.Item
        name='title'
        label={translations().title}
      >
        <Input
          onChange={(event) => setFilter({ title: event.target.value })}
          onBlur={loadNotifications}
        />
      </Form.Item>
    }
  ]

  return (
    <>
      <FormSearch
        form={form}
        onReset={onReset}
        isCollapsible={true}
        items={items}
      />
    </>
  )
})