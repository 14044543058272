import React from 'react'
import styles from './styles.scss'
export const TableRow = () => {
  return (
    <div className={styles.tableRow}>
      <div className={styles.tableItem} />
      <div className={styles.tableItemLong} />
      <div className={styles.tableItem} />
      <div className={styles.tableItem} />
    </div>
  )
}