import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Button, Card, Col, Divider, Flex, Form, Input, Row, Select, Space, Typography } from 'antd'
import { NamePath } from 'antd/lib/form/interface'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  TiptapEditor
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TiptapEditor'
import {
  TemplatePreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplatePreview'
import { NotificationTypeEnum } from '~/code/stores/notification/types'
import { TemplateType } from '~/code/stores/NotificationManagementStore'
import { removeHtmlTags } from '~/code/stores/NotificationManagementStore/services'
import {
  formLayout
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplateSetup/constants'
import { ITemplateManagementStore } from '~/code/pages/Notifications'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { NotificationDisplayTypeEnum } from '~/code/models'
import translations from './translations'
import styles from './styles.scss'

export const TemplateManagementStoreSymbol = Symbol('TemplateManagementStoreSymbol')

const {labelCol} = formLayout

export const TemplateSetup = observer(() => {
  const store = useInjection<ITemplateManagementStore>(TemplateManagementStoreSymbol)
  const [isSaveDisabled, setSaveDisabled] = useState(true)

  const [form] = Form.useForm()

  useEffect(() => {
    formValidation()
  }, [])

  const {
    isSystemOptionsLoading,
    isOptionsLoading,
    setTemplateForm,
    onDisplayTypeChangeReset,
    templateForm,
    templatePreviewConfig,
    systemOptions,
    displayTypeOptions,
    notificationTypesOptions,
    contentAreaOptions,
    levelOptions,
    saveTemplate
  } = store

  const {id, notificationTypeId, systemId, notificationDisplayTypeId, descriptionLine1} = templateForm
  const btnOptions = [{value: 'default', label: 'Default'}, {value: 'primary', label: 'Primary'}]

  const formValidation = () => {
    let allValues = {}
    const fieldsError = form.getFieldsError()
    const hasErrors = fieldsError.some(({ errors }) => errors.length > 0)
    const displayType = form.getFieldValue('notificationDisplayTypeId' as NamePath)
    const fields: NamePath[] = ['title', 'systemId', 'notificationDisplayTypeId', 'notificationContentAreaId', 'descriptionLine1', 'notificationLevelId'] as NamePath[]

    switch (displayType) {
      case NotificationDisplayTypeEnum.BANNER:
        allValues = form.getFieldsValue(fields)
        break
      case NotificationDisplayTypeEnum.POPUP:
        allValues = form.getFieldsValue(fields.filter((field) => field !== 'notificationLevelId'))
        break
      case NotificationDisplayTypeEnum.BLOCK:
        allValues = form.getFieldsValue(fields.filter((field) => field !== 'notificationLevelId'))
        break
      default: allValues = form.getFieldsValue(fields)
    }

    const requiredFieldsFilled = Object.values(allValues).every((value) => {
      return removeHtmlTags(value as string) !== undefined && removeHtmlTags(value as string) !== ''
    })
    const formIsValid = !hasErrors && requiredFieldsFilled
    setSaveDisabled(!formIsValid)
  }

  const resetAllFields = (values: Partial<TemplateType>) => {
    return {
      ...values,
      notificationDisplayTypeId: undefined,
      notificationLevelId: undefined,
      notificationContentAreaId: undefined,
      title: undefined,
      descriptionLine1: undefined,
      settings: undefined
    }
  }

  const onTemplateFormChange = (values: Partial<TemplateType>) => {
    const [[key, value]] = Object.entries(values)

    switch (key) {
      case 'systemId':
        form.setFieldsValue(resetAllFields(values))
        break
      case 'notificationDisplayTypeId':
        form.setFieldsValue({...values, ...onDisplayTypeChangeReset(value)})
        setTemplateForm({...values, ...onDisplayTypeChangeReset(value)} as Partial<TemplateType>)
        break
      case 'settings':
        break
      default:
        form.setFieldsValue(values)
        break
    }

    setTemplateForm(form.getFieldsValue())
    formValidation()
  }

  const onDescriptionChange = (value: string) => {
    form.setFieldValue('descriptionLine1', value)
    onTemplateFormChange({descriptionLine1: value})
  }
  
  const hasPrimaryBtn = () => {
    const settings = form.getFieldValue(['settings'])
    return settings?.buttons?.some(
      (button: {
        actionButtonUrl: string,
        actionButtonType: 'primary' | 'default',
        actionButtonName: string
      }) => button?.actionButtonType === 'primary'
    )
 }

  const onRemoveBtn = (index: number) => {
    const settings = templateForm.settings || { buttons: [] }
    const updatedButtons = settings.buttons.filter((_, btnIndex) => btnIndex !== index)
    onTemplateFormChange({ settings: { ...settings, buttons: updatedButtons } })
  }

  const onFormFinish = () => {
    saveTemplate().then(() => {
      goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES)
      setTemplateForm(null)
    })
  }

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <Card title={translations().notificationDetails}>
            <Form
              labelCol={labelCol}
              form={form}
              onValuesChange={(changedValues) => onTemplateFormChange(changedValues)}
              initialValues={templateForm}
              onFinish={onFormFinish}
              validateTrigger={'onChange'}
            >
              <Form.Item shouldUpdate noStyle>
                {() => {
                  return id && notificationTypeId ? (
                    <Form.Item
                      label={translations().notificationType}
                      name='notificationTypeId'
                      rules={[{ required: true, message: translations().levelRequired }]}
                    >
                      <Select options={notificationTypesOptions}
                      />
                    </Form.Item>
                  ) : null
                }}
              </Form.Item>
              <Form.Item
                label={translations().system}
                name='systemId'
                rules={[{ required: true, message: translations().systemRequired }]}
              >
                <Select
                  disabled={isSystemOptionsLoading}
                  options={systemOptions}
                />
              </Form.Item>
              <Form.Item
                label={translations().displayType}
                name='notificationDisplayTypeId'

                rules={[{ required: true, message: translations().displayTypeRequired }]}
              >
                <Select
                  loading={isOptionsLoading}
                  disabled={isOptionsLoading || !systemId}
                  options={displayTypeOptions}

                />
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {() => {
                  return notificationDisplayTypeId ===
                  'banner' ? (
                    <Form.Item
                      label={translations().level}
                      name='notificationLevelId'
                      rules={[{ required: true, message: translations().levelRequired }]}
                    >
                      <Select
                        options={levelOptions}
                        value={templateForm.notificationLevelId}
                      />
                    </Form.Item>
                  ) : null
                }}
              </Form.Item>
              <Form.Item
                label={translations().contentArea}
                name='notificationContentAreaId'
                rules={[{ required: true, message: translations().contentAreaRequired }]}
              >
                <Select
                  value={templateForm.notificationContentAreaId}
                  disabled={isOptionsLoading || !notificationDisplayTypeId}
                  loading={isOptionsLoading}
                  options={contentAreaOptions}
                />
              </Form.Item>
              <Form.Item
                label={translations().templateTitle}
                name='title'
                rules={[
                  {
                    required: true,
                    message: translations().templateTitleRequired
                  },
                  {
                    max: 35,
                    message: translations().charactersRestrictions(35)
                  },
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.resolve()
                      }
                      if (value.trim().length === 0) {
                        return Promise.reject(translations().validTitle)
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input
                  value={templateForm.title}
                  maxLength={35}
                  showCount={true}
                />
              </Form.Item>
              <Form.Item
                label={translations().templateDescription}
                name='descriptionLine1'
                rules={[
                  {
                    required: true,
                    message: translations().templateDescriptionRequired
                  },
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.resolve()
                      }

                      const cleanValue = removeHtmlTags(value)

                      if (cleanValue.length > 200) {
                        return Promise.reject(translations().charactersRestrictions(200))
                      }

                      if (cleanValue.trim().length === 0) {
                        return Promise.reject(translations().validDescription)
                      }

                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <TiptapEditor onChange={onDescriptionChange} value={descriptionLine1} />
                <div className={styles.counterContainer}>
                  {removeHtmlTags(descriptionLine1).length || 0} / 200
                </div>
              </Form.Item>
              {notificationDisplayTypeId === NotificationTypeEnum.POPUP ? (
                <Form.List name={['settings', 'buttons']}>
                  {(fields, { add, remove }) => (
                    <>
                      <Divider />
                      <Flex justify={'space-between'}>
                        <Typography.Title level={5}>Action buttons</Typography.Title>
                        {!fields.length ? (
                          <Button
                            size={'small'}
                            type={'dashed'}
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            {translations().addButton}
                          </Button>
                        ) : null}
                      </Flex>
                      {fields.map(({ key, name }) => (
                        <Space key={key} direction={'vertical'} size={'small'} style={{ width: '100%' }}>
                          <Card>
                            <Form.Item
                              name={[name, 'actionButtonName']}
                              label={translations().buttonName}
                              validateTrigger={'onChange'}
                              rules={[
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    const buttons = getFieldValue(['settings', 'buttons']);
                                    if (buttons?.length > 0 && (!value || value.trim().length === 0)) {
                                      return Promise.reject(new Error(translations().buttonNameRequired));
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Input maxLength={20} showCount={true} />
                            </Form.Item>

                            <Form.Item
                              name={[name, 'actionButtonUrl']}
                              label={translations().buttonUrl}
                              rules={[
                                {
                                  validator: (_, value) => {
                                    if (!value) return Promise.resolve();
                                    const urlPattern =
                                      /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/i;
                                    return urlPattern.test(value)
                                      ? Promise.resolve()
                                      : Promise.reject(new Error(translations().validUrl));
                                  },
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item name={[name, 'actionButtonType']} label={translations().buttonType}>
                              <Select
                                options={btnOptions.map((option) => ({
                                  ...option,
                                  disabled: option.value === 'primary' && hasPrimaryBtn(),
                                }))}
                              />
                            </Form.Item>
                            <Flex gap={'small'} justify={'end'}  style={{ marginBottom: 0 }}>
                              <Form.Item style={{ marginBottom: 0 }}>
                                <Button
                                  size={'small'}
                                  type="dashed"
                                  style={{ color: 'red' }}
                                  onClick={() => {
                                    remove(name);
                                    onRemoveBtn(name);
                                  }}
                                  icon={<MinusCircleOutlined />}
                                >
                                  {translations().remove}
                                </Button>
                              </Form.Item>
                            </Flex>
                          </Card>
                          <Flex gap={'small'} justify={'end'} style={{ marginBottom: 8 }}>
                            {name === fields.length - 1 && fields.length < 3 ? (
                              <Button size={'small'} type={'dashed'} onClick={() => add()} icon={<PlusOutlined />}>
                                {translations().addButton}
                              </Button>
                            ) : null}
                          </Flex>
                        </Space>
                      ))}
                    </>
                  )}
                </Form.List>
              ) : null}
              <Form.Item>
                <Flex justify={'end'}>
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    disabled={isSaveDisabled}
                  >{translations().save}</Button>
                </Flex>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <Card title={translations().preview}>
            <TemplatePreview templateForm={templateForm} previewConfig={templatePreviewConfig} />
          </Card>
        </Col>
      </Row>
    </>
  )
})