import translations from './translations'

translations.add('en', {
    web: 'Web',
    mobile: 'Mobile',
    notificationDetails: 'Notification Details',
    templateName: 'Template Name',
    system: 'System',
    displayType: 'Display Type',
    level: 'Level',
    contentType: 'Content Type',
    templateTitle: 'Title',
    templateDescription: 'Description',
    buttonUrl: 'Button URL',
    buttonName: 'Button Text',
    back: 'Back',
    save: 'Save',
    preview: 'Preview',
    displayTypeRequired: 'Display Type is required',
    systemRequired: 'System is required',
    templateNameRequired: 'Template Name is Required',
    levelRequired: 'Level is required',
    contentArea: 'Content Area',
    contentAreaRequired: 'Content Area is Required',
    templateTitleRequired: 'Title is Required',
    templateDescriptionRequired: 'Description is Required',
    type: 'Type',
    cancel: 'Cancel',
    notificationType: 'Notification Type',
    cancelTemplateTitle: 'You are about to cancel the template creation process',
    cancelTemplateContent: 'Please note that all changes will not be saved. Are you certain you wish to proceed?',
    charactersRestrictions: (value: number) => `Up to ${value} characters are permitted`,
    validUrl: 'Please enter a valid URL',
    validDescription: 'Please enter valid description',
    validTitle: 'Please enter valid title',
    buttonType: 'Button Type',
    buttonNameRequired: 'Button Text is required',
    addButton: 'Add Button',
    remove: 'Remove'
})
