import React from 'react'
import {
  FullBlockMockup
} from '~/code/components/NotificationsManagement/FullBlockMockup/FullBlockMockup'
import { SectionMockup } from '~/code/components/NotificationsManagement'
import { NotificationDisplayTypeEnum } from '~/code/models'
import { useTemplateConfig } from '~/code/hooks'

export const FullBlockMobilePreview = () => {
  const config = useTemplateConfig()
  const {templateForm} = config
  const {notificationDisplayTypeId, notificationContentAreaId} = templateForm

  return notificationDisplayTypeId === NotificationDisplayTypeEnum.BLOCK && notificationContentAreaId === 'app' ? (
    <FullBlockMockup isMobile={true} templateConfig={config}/>
  ) : <SectionMockup isMobile={true} />
}