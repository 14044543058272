import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { injectable } from 'inversify'
import { message } from 'antd'
import {
  createTemplate,
  deleteTemplateById,
  fetchLevels,
  fetchSystems,
  fetchTemplate, formatDataToSubmit, formatToConfig,
  getContentAreas,
  getDisplayTypes,
  handleFulfilled, updateTemplate
} from '~/code/stores/NotificationManagementStore/services'
import {
  NotificationTemplateEnum,
  TemplateType
} from '~/code/stores/NotificationManagementStore/models'
import { NotificationDisplayTypeEnum, SelectItem } from '~/code/models'
import { TemplatePreviewConfigType } from '~/code/stores/NotificationManagementStore/models/TemplatePreviewConfigType'
import { ITemplateManagementStore } from '~/code/pages/Notifications'
import { DisplayTypeSelectItem } from '~/code/stores/NotificationManagementStore/models/DisplayTypeSelectItem'
import translations from './translations'

@injectable()
export class TemplateManagementStore implements ITemplateManagementStore {
  isTemplateCreating: boolean = false
  isLoading: boolean = false
  isSystemOptionsLoading = false
  isOptionsLoading = false
  isLevelsLoading = false
  isApproveModalOpen = false
  allContentAreas = null
  contentAreaOptions: SelectItem[] = []
  systemOptions: SelectItem[] = []
  displayTypeOptions: DisplayTypeSelectItem[] = []
  levelOptions: SelectItem[] = []
  isDisplayTypesLoading = false
  templateForm: TemplateType = null
  templatePreviewConfig: TemplatePreviewConfigType = null
  notificationTypesOptions: SelectItem[] = [
    {label: 'Maintenance / Incident', value: NotificationTemplateEnum.MAINTENANCE},
    {label: 'Marketing', value: NotificationTemplateEnum.MARKETING}
  ]
  constructor() {
    makeObservable(this, {
      isLoading: observable,
      isTemplateCreating: observable,
      isDisplayTypesLoading: observable,
      isApproveModalOpen: observable,
      displayTypeOptions: observable,
      isSystemOptionsLoading: observable,
      contentAreaOptions: observable,
      isOptionsLoading: observable,
      templateForm: observable,
      templatePreviewConfig: observable,
      setTemplateForm: action.bound,
      setApproveModalOpen: action.bound,
      clearTemplateForm: action.bound
    })
    this.fetchSystems()

    reaction(() => this.templateForm?.systemId, () => {
      const {systemId, notificationTypeId} = this.templateForm || {}
      if (systemId && notificationTypeId) {
        this.fetchOptions()
      }
    })

    reaction(() => this.templateForm?.notificationDisplayTypeId, () => {
      this.displayTypeReaction()
    })
  }

  private displayTypeReaction = async () => {
    if (!this.displayTypeOptions.length) {
      await this.fetchOptions()
    }

    if (this.templateForm?.notificationDisplayTypeId === NotificationDisplayTypeEnum.BANNER) {
      this.loadLevels()
    }

    const {displayTypeId: typeId} = this.displayTypeOptions && this.displayTypeOptions.find(({value}) => value === this.templateForm?.notificationDisplayTypeId)
    const {contentAreas} = this.allContentAreas && this.allContentAreas.find(({displayTypeId}) => displayTypeId === typeId)

    runInAction(() => this.contentAreaOptions = contentAreas && contentAreas.map(({id, description}) => ({
      value: id,
      label: description
    })))
  }

  public setTemplateForm = (values: Partial<TemplateType>) => {
    this.templateForm = {
      ...this.templateForm,
      ...values
    }
    this.setTemplatePreviewConfig()
  }

  public onDisplayTypeChangeReset = (value: NotificationDisplayTypeEnum): Partial<TemplateType> => {
    const {systemId} = this.templateForm

    switch(value) {
      case NotificationDisplayTypeEnum.BANNER:
        return {
          notificationLevelId: 'info',
          notificationContentAreaId: 'login',
          settings: undefined,
        }
      case NotificationDisplayTypeEnum.POPUP:
        return {
          notificationLevelId: undefined,
          notificationContentAreaId: 'auth',
          settings: undefined,
        }
      case NotificationDisplayTypeEnum.BLOCK:
        if (systemId === 'dashboard') {
          return {
            notificationLevelId: undefined,
            notificationContentAreaId: undefined,
            settings: undefined
          }
        } else return {
          notificationLevelId: undefined,
          notificationContentAreaId: 'app',
          settings: undefined
        }
      default: return {
        notificationLevelId: undefined,
        notificationContentAreaId: undefined,
        settings: undefined,
      }
    }
  }

  public clearTemplateForm = () => {
    this.templateForm = null
  }

  public setTemplatePreviewConfig = () => {
    this.templatePreviewConfig = formatToConfig(this.templateForm)
  }

  public setApproveModalOpen = (value: boolean) => {
    this.isApproveModalOpen = value
  }

  public deleteTemplate = async (id: string) => {
    try {
      const { result, error } = await deleteTemplateById(id)

      if (error) throw new Error(error.message)

      message.success(result['message'])
    } catch (error) {
      message.error(error.message)
    }
    finally {
      this.setApproveModalOpen(false)
    }
  }

  public async fetchSystems(){
    runInAction(() => this.isSystemOptionsLoading = true)

    try {
      const {result, error} = await fetchSystems()

      if (error) throw new Error(error.message)

      runInAction(() => this.systemOptions = result.map(({id, name}) => ({
        value: id,
        label: name
      })))

    } catch(error) {
      console.error(error.message)
    } finally {
      runInAction(() => this.isSystemOptionsLoading = false)
    }
  }

  public async loadLevels() {
    runInAction(() => this.isLevelsLoading = true)

    try {
      const {result} = await fetchLevels()

      runInAction(() => this.levelOptions = result.map(({id, name}) => ({
        value: id,
        label: name
      })))

    } catch(error) {
      console.error(error.message)
    }
  }

  public async fetchOptions() {
    const {systemId, notificationTypeId} = this.templateForm
    const data = {systemId, notificationTypeId}

    runInAction(() => this.isOptionsLoading = true)

    try {
      const [contentAreasPromise, displayTypesPromise] = await Promise.allSettled([
        getContentAreas(data),
        getDisplayTypes(notificationTypeId)
      ])

      const contentAreas = handleFulfilled(contentAreasPromise)
      const displayTypes = handleFulfilled(displayTypesPromise)

      runInAction(() => {
        this.displayTypeOptions = displayTypes[this.templateForm.systemId].map(({id, description, displayTypeId, levels}) => {
          if (levels && levels.length) {
            this.levelOptions = levels.map(({id, name}) => ({
              value: id,
              label: name,
            }))
          }

          return {
            value: id,
            label: description,
            displayTypeId: displayTypeId
          }
        })

        this.allContentAreas = contentAreas
      })
    } catch(error) {
      message.error(error.message)
    } finally {
      runInAction(() => this.isOptionsLoading = false)
    }
  }

  public async loadData(id: string) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const {result, error} = await fetchTemplate(id)
      if (error) throw new Error(error.message)

      this.setTemplateForm(result)
    } catch(e) {
      console.log(e.message)
    } finally {
      runInAction(() => this.isLoading = false)
    }
  }

  public saveTemplate = async() => {
    runInAction(() => this.isTemplateCreating = true)

    try {
      const data = formatDataToSubmit(this.templateForm)
      const {id} = this.templateForm

      const {result, error} = id ? await updateTemplate(data, id) : await createTemplate(data)

      if (error) throw new Error(error.message)
      message.success(`${result.title} ${translations().notificationSaved}`)
    } catch(error) {
      message.error(error.message)
    } finally {
      runInAction(() => this.isTemplateCreating = false)
    }
  }
}