import { ColumnType } from 'antd/lib/table'
import translations from '../translations'

export const perBankingDateColumns: ColumnType<any>[] = [
    {
        title: translations().bankingDate,
        dataIndex: 'bankingDate',
        key: 'bankingDate',
        width: 140
    },
    {
        title: translations().settlementDate,
        dataIndex: 'settlementDate',
        key: 'settlementDate',
        width: 140,
        render: (value) => value ?? translations().notSettledYet
    },
    {
        title: translations().count,
        dataIndex: 'count',
        key: 'count',
        width: 100
    },
    {
        title: translations().amount,
        dataIndex: 'amount',
        key: 'amount',
        width: 100
    },
    {
        title: translations().currency,
        dataIndex: 'currency',
        key: 'currency',
        width: 100
    },
    {
        title: translations().settlementType,
        dataIndex: 'settlementType',
        key: 'settlementType',
        width: 100
    },
    {
        title: translations().totalFee,
        dataIndex: 'feeAmount',
        key: 'feeAmount',
        width: 100
    },
    {
        title: translations().amountToSettle,
        dataIndex: 'amountToMerchant',
        key: 'amountToMerchant',
        width: 150
    }
]
