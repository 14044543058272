import React from 'react'
import { PopupNotificationProps } from './props'
import { Button, Flex, Modal } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { notificationDescription } from '~/code/services'
import styles from './styles.scss'
import translations from './translations'

export const PopupNotification: React.FC<PopupNotificationProps> = ({ notification, onCloseNotification }) => {
    const { id, title, description, settings } = notification || {}

    const handleClose = () => {
        onCloseNotification(id)
    }

    return (
        <>
            <Modal
                open={true}
                closable={false}
                title={(
                  <Flex justify={'space-between'}>
                      <div>{title}</div>
                      <div>
                          <Button icon={<CloseOutlined />} size={'small'} onClick={handleClose} type={'text'} />
                      </div>
                  </Flex>
                )}
                footer={(
                  <Flex gap={'small'} justify={'end'}>
                      {settings?.buttons?.map((button) => (
                        <Button
                         type={button.actionButtonType}
                         onClick={() => button.actionButtonUrl ? window.open(button.actionButtonUrl) : handleClose()}
                       >
                           {button.actionButtonName || translations().ok}
                       </Button>
                     ))}
                 </Flex>
                )}
            >
                <div className={styles.description} dangerouslySetInnerHTML={{ __html: notificationDescription(description) }} />
            </Modal>
        </>
    )
}
