import React from 'react'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import {
  SectionMockup
} from '~/code/components/NotificationsManagement/SectionMockup/SectionMockup'
import { notificationDescription } from '~/code/services'
import { useTemplateConfig } from '~/code/hooks'
import styles from './styles.scss'



export const PopupPreview = () => {
  const {templateForm, previewConfig} = useTemplateConfig()
  const {title, descriptionLine1, settings} = templateForm

  return (
    <>
      <div className={styles.contentContainer}>
        <div className={styles.overlay} />
        <SectionMockup />
        <div className={styles.modalContainer}>
          <div className={styles.titleContainer}>
            <div>{title}</div>
            <Button size='small' type='text' icon={<CloseOutlined />} />
          </div>
          <div className={styles.descriptionContainer}>
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: notificationDescription(descriptionLine1) }}></div>
          </div>
          <div className={styles.buttonsContainer}>
            {settings?.buttons && settings?.buttons.map((button, index) => (
              button?.actionButtonName ? (
                <Button
                  size={'small'}
                  style={button?.actionButtonType === 'primary' ? { ...previewConfig.primaryButton } : null}
                  onClick={button?.actionButtonUrl ? () => window.open(button?.actionButtonUrl) : null}
                  key={index}
                >{button?.actionButtonName}</Button>
              ) : null
            ))}
          </div>
        </div>
      </div>
    </>
  )
}