import React from 'react'
import classNames from 'classnames'
import {CheckOutlined, CloseOutlined, InfoCircleOutlined, WarningOutlined} from '@ant-design/icons'
import { notificationDescription } from '~/code/services'
import {BannerNotificationProps} from './props'
import styles from './styles.scss'

export const BannerNotification: React.FC<BannerNotificationProps> = ({notification, onCloseNotification, options= {corners: 'square'}}) => {
    const {id, notificationLevelId, title, description, isClosable} = notification
    const {corners} = options

    const renderNotificationIcon = (notificationLevel) => {
        switch (notificationLevel) {
            case 'info':
                return <InfoCircleOutlined className={styles.icon}/>
            case 'success':
                return <CheckOutlined className={styles.icon}/>
            case 'warning':
                return <WarningOutlined className={styles.icon}/>
            case 'error':
                return <CloseOutlined className={styles.icon}/>
            default:
                return null
        }
    }

    return <div className={classNames(styles.notificationContainer, styles[notificationLevelId], {[styles.cornersRound]: corners && corners === 'round'})}>
        <div className={styles.iconContainer}>
            {renderNotificationIcon(notificationLevelId)}
        </div>
        <div>
            <div>
                <span className={styles.title}>{title}</span>
            </div>
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: notificationDescription(description) }}/>
        </div>

        {
            isClosable &&
            <div
                onClick={() => onCloseNotification(id)}
                className={classNames(styles.closeButton, styles[notificationLevelId])}
            />
        }
    </div>
}
