import React, { useEffect } from 'react'
import { Alert, Button, Checkbox, Col, Form, Input, Popover, Row, Select, Switch } from 'antd'
import { EcomFieldsFormPropsType } from './props'
import translations from './translations'
import { FormSubgroupTitle } from '../../../shared'
import styles from './EcomFieldsForm.scss'
import { DeleteRedOutlined } from '~/assets/icons'
import classNames from 'classnames'
import { subscriptionServicePeriodOptionsDNA } from '../../constants'
import { URL_PATTERN_2 } from '~/code/constants/Patterns'
import { DNA_DOMAINS } from '~/code/pages/StartProcess/services/constants'

export const EcomFieldsForm: React.FC<EcomFieldsFormPropsType> = props => {
  const { form, editMode, website } = props

  useEffect(() => {
    !editMode &&
      form.setFieldsValue({
        payByLink: true,
        virtualTerminal: false,
        websitePayments: false,
        zashDigital: false,
        amex: false,
        unionPay: true,
        discovery: true,
        openBanking: true,
        payByBank: true,
        aliPay: false,
        cards: true
      })
  }, [])

  useEffect(() => {
    if (form.getFieldValue('websitePayment')) {
      form.setFieldsValue({ storeWebSite: 'test.com' })
    }
  }, [form])

  return (
    <div className={styles.itemBorder}>
      {!editMode && (
        <Row gutter={24}>
          <Col span={12}>
            <FormSubgroupTitle>{translations().configureEcom}</FormSubgroupTitle>
          </Col>
          <Col className={styles.rightAlign} span={12}>
            <Button type='text' icon={<DeleteRedOutlined />} onClick={() => form.resetFields()} />
          </Col>
        </Row>
      )}
      <Form.Item shouldUpdate noStyle>
        {t => (
          <>
            <p className={classNames(styles.styledP, styles.mt10)}>{translations().paymentMethods}</p>

            <Row className={styles.mt15} gutter={24}>
              <Col span={12}>
                <Form.Item className={styles.h10} name={'cards'} valuePropName={'checked'}>
                  <Checkbox disabled>{translations().cards} </Checkbox>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item className={styles.h10} name={'payByBank'} valuePropName={'checked'}>
                  <Checkbox>{translations().payByBank} </Checkbox>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item className={styles.h10} name={'aliPay'} valuePropName={'checked'}>
                  <Checkbox disabled> {translations().aliPay} </Checkbox>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item className={styles.h10} name={'openBanking'} valuePropName={'checked'}>
                  <Checkbox>{translations().openBanking} </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <p className={classNames(styles.styledP, styles.mt15)}>{translations().productConfiguration}</p>
              </Col>
              <Col span={12}>
                <p className={classNames(styles.styledP, styles.mt15)}>{translations().minTerm}</p>
              </Col>
            </Row>

            <Row className={styles.mt15} gutter={24}>
              <Col span={12}>
                <Form.Item className={styles.h10} name={'payByLink'} valuePropName={'checked'}>
                  <Checkbox disabled>{translations().payByLink} </Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className={styles.h10}
                  name={'subscriptionServicePeriod'}
                  rules={[
                    {
                      required: true,
                      message: translations().minTermRequired
                    }
                  ]}
                >
                  <Select options={subscriptionServicePeriodOptionsDNA} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className={styles.h10} name={'virtualTerminal'} valuePropName={'checked'}>
                  <Checkbox>{translations().virtualTerminal} </Checkbox>
                </Form.Item>
              </Col>
              <Col span={12} />

              <Col span={12}>
                <Form.Item className={styles.h10} name={'websitePayments'} valuePropName={'checked'}>
                  <Checkbox
                    onChange={e =>
                      form.setFieldsValue({
                        websitePayments: e.target.checked,
                        storeWebSite: e.target.checked ? website : ''
                      })
                    }
                  >
                    {translations().websitePayments}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label={<p className={styles.styledP}>{translations().storeWebSite}</p>}
              name='storeWebSite'
              validateTrigger={['onChange', 'onBlur']}
              rules={[
                {
                  pattern: URL_PATTERN_2,
                  message: translations().websiteInvalid
                },
                {
                  required: true,
                  message: translations().webSiteRequired
                },
                {
                  validator: async (rule, value) => {
                    if (!value) {
                      return
                    }
                    const isDNADomain = Boolean(DNA_DOMAINS.some(d => value.toUpperCase().includes(d.toUpperCase())))
                    if (value && isDNADomain) {
                      throw new Error(translations().invalidWebsiteDomain)
                    }
                  }
                }
              ]}
            >
              <Input placeholder={translations().ecomWebsitePlaceholder} className={styles.halfWidth} />
            </Form.Item>

            <Alert message={<p className={styles.errMsg}>{translations().websitePopover}</p>} type='warning' />
          </>
        )}
      </Form.Item>
    </div>
  )
}
