import { deleteWithAuth, getWithAuth, patchWithAuth, putWithAuth } from '~/code/services'
import { postWithAuth } from '~/code/services'
import {
  FormOptionsType,
  LevelsType, NotificationType,
  TemplateType
} from '~/code/stores/NotificationManagementStore'
import { TemplatesResponseType } from '~/code/stores/NotificationManagementStore/models/TemplatesResponseType'
import { TeammateRequestType, TeammateResponseType } from '~/code/stores/TeammatesStore/models'
import { NotificationsResponseType } from '~/code/stores/NotificationManagementStore/models/NotificationsResponseType'
import { ContentAreasResponseType } from '~/code/stores/NotificationManagementStore/models/ContentAreasResponseType'
import { DisplayTypesResponseType } from '~/code/stores/NotificationManagementStore/models/DisplayTypesResponseType'
import { NotificationsFilterType } from '~/code/stores/NotificationManagementStore/models/NotificationsFilterType'

export const fetchSystems = () =>
  getWithAuth<FormOptionsType[]>('/api/v1/notification-management/systems')

export const fetchDisplayTypes = () =>
  getWithAuth<FormOptionsType[]>('/api/v1/notification-management/display-types')

export const getDisplayTypes = (notificationTypeId: string) =>
  getWithAuth<DisplayTypesResponseType>(`/api/v2/notification-management/display-types?notificationTypeId=${notificationTypeId}`)

export const fetchLevels = () =>
  getWithAuth<LevelsType[]>('/api/v1/notification-management/levels')

export const fetchContentAreas = (system) =>
  getWithAuth<FormOptionsType[]>('/api/v1/notification-management/content-areas', {system})

export const getContentAreas = ({systemId, notificationTypeId}: {systemId: string, notificationTypeId: string}) =>
  getWithAuth<ContentAreasResponseType[]>(`/api/v2/notification-management/content-areas?notificationTypeId=${notificationTypeId}&systemId=${systemId}`)

export const fetchTemplates = (params: NotificationsFilterType) =>
  getWithAuth<TemplatesResponseType>('/api/v1/notification-management/templates', params)

export const fetchTemplate = (id: string) =>
  getWithAuth<TemplateType>(`/api/v1/notification-management/templates/${id}`)

export const deleteTemplateById = (id: string) =>
  deleteWithAuth(`/api/v1/notification-management/templates/${id}`)

export const createTemplate = (data: any) =>
  postWithAuth<any>(`/api/v1/notification-management/templates`, data)

export const updateTemplate = (data: any, id: string) =>
  patchWithAuth<any>(`/api/v1/notification-management/templates/${id}`, data)

export const fetchTeammates = (params: TeammateRequestType = {}) => {
  return getWithAuth<TeammateResponseType>('/auth/oauth2/teammates', params)
}

export const fetchNotifications = (params: any) =>
  getWithAuth<NotificationsResponseType>(`/api/v1/notification-management/notifications`, params)

export const fetchNotification = (id: string) =>
  getWithAuth<NotificationType>(`/api/v1/notification-management/notifications/${id}`)

export const createNotification = (data: any) =>
  postWithAuth<any>(`/api/v1/notification-management/notifications`, data)


export const deactivateNotificationById = (id: string) =>
  putWithAuth<NotificationType>(`/api/v1/notification-management/notifications/deactivate/${id}`, {isActive: false})

