import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'
import { SettlementFormValuesType } from '../models'

export default TranslationBuilder.create<{
  errorDefiningSettlementType: string
  settlementType: string
  chargeMerchant: string
  changeSettlement: string
  dailyNet: string
  monthlyNet: string
  grossDD: string
  dailyGross: string
  submit: string
  settlementPayment: string
  perOutlerMid: string
  perCompanyUberId: string
  title: string
  settlementTypeNote: () => ReactNode
  dailyNetSettlements: string
  monthlyNetSettlements: string
  grossSettlementsByMid: (
    numberOfBankDetailsForDirectDebit: number,
    settlementFormValues: SettlementFormValuesType
  ) => ReactNode
  grossSettlementsByUberId: (
    numberOfBankDetailsForDirectDebit: number,
    settlementFormValues: SettlementFormValuesType
  ) => ReactNode
  settlementImmediate: string
  settlementPaymentNote: string
  byMid: string
  byUberId: string
  settlementImmediateNote: () => ReactNode
}>()
