import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select, Table } from 'antd'
import { isEmpty } from 'dna-common'
import { DeleteRedOutlined } from '~/assets/icons'
import {
  chargeTypes,
  subscriptionServicePeriodOptionsDNA,
  subscriptionServicePeriodOptionsPaytek,
  terminalTypes
} from '../../constants'
import { PosDeliveryInfo } from '../PosDeliveryInfo'
import { feesDataRegularForProducts, productColumns, terminalPurchaseData } from './services/generators'
import { FormSubgroupTitle } from '../../../shared'
import { PosFieldsFormPropsType } from './props'
import translations from './translations'
import styles from './PosFieldsForm.scss'

export const PosFieldsForm: React.FC<PosFieldsFormPropsType> = props => {
  const {
    form,
    isPaytek,
    isPosModelsLoading,
    posModels,
    isBundlesLoading,
    handleChargeTypeChange,
    storeForm,
    companyProfile,
    onboardingSettings,
    posDeliveryAddress,
    posDeliveryAddressType,
    showDeliveryInfo,
    posDeliveryInstructions,
    editMode,
    editEnabled,
    isSME,
    isISO,
    deliveryPhoneNumber,
    posModelsFull,
    getBundlesByPosCode,
    subscriptionPrices
  } = props

  const posModel = form.getFieldValue('model')
  const [posBundles, setPosBundles] = useState([])
  const [subscriptionFee, setSubscriptionFee] = useState('')

  const [zashEnabled, setZashEnabled] = useState(false)

  useEffect(() => {
    !editMode &&
      form.setFieldsValue({
        moto: false,
        zashEpos: false,
        aliPay: false,
        cards: true
      })
  }, [])

  useEffect(() => {
    getBundlesByPosCode && getBundlesByPosCode(posModel).then(res => setPosBundles(res))
  }, [posModel])

  return (
    <div className={styles.itemBorder}>
      {!editMode && (
        <Row gutter={24}>
          <Col span={12}>
            <FormSubgroupTitle>{translations().configurePos}</FormSubgroupTitle>
          </Col>
          <Col className={styles.rightAlign} span={12}>
            <Button type='text' icon={<DeleteRedOutlined />} onClick={() => form.resetFields()} />
          </Col>
        </Row>
      )}
      <Form.Item noStyle shouldUpdate>
        {t => (
          <>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name='model'
                  rules={[{ required: true, message: translations().posModelRequired }]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Select
                    loading={isPosModelsLoading}
                    options={posModels}
                    onSelect={e => {
                      getBundlesByPosCode && getBundlesByPosCode(posModel).then(res => setPosBundles(res))

                      form.setFieldsValue({ zashEpos: posModelsFull.find(t => t.code === e)?.zash || false })
                      setZashEnabled(posModelsFull.find(t => t.code === e)?.zash || false)
                      if (
                        form.getFieldValue('terminalType') === 'subscription' &&
                        form.getFieldValue('subscriptionServicePeriod')
                      ) {
                        setSubscriptionFee(
                          subscriptionPrices?.find(
                            s => s.posModel === Number(e) && s.code === form.getFieldValue('subscriptionServicePeriod')
                          )?.value || ''
                        )
                      }
                    }}
                    onChange={() => t.resetFields(['posBundles', 'feeSubscriptionPerMonth'])}
                    placeholder={translations().selectDeviceType}
                  />
                </Form.Item>
              </Col>
              <Col span={12} className={styles.flex}>
                <span className={styles.mr10}>{translations().posQuantity}</span>
                <Form.Item
                  name='count'
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      message: translations().posCountRequired
                    },
                    {
                      type: 'number',
                      min: 1,
                      max: 100,
                      message: translations().posCountError
                    }
                  ]}
                >
                  <InputNumber min={1} />
                </Form.Item>
              </Col>
            </Row>

            <p className={styles.styledP}>{translations().paymentMethods}</p>

            <Row className={styles.mt15} gutter={24}>
              <Col span={12}>
                <Form.Item className={styles.h10} name={'cards'} valuePropName={'checked'}>
                  <Checkbox disabled>{translations().cards} </Checkbox>
                </Form.Item>
              </Col>
              <Col span={12} />

              <Col span={12}>
                <Form.Item className={styles.h10} name={'aliPay'} valuePropName={'checked'}>
                  <Checkbox disabled> {translations().aliPay} </Checkbox>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item className={styles.h10} name={'moto'} valuePropName={'checked'}>
                  <Checkbox>{translations().motoCnp} </Checkbox>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item className={styles.h10} name={'posOpenBanking'} valuePropName={'checked'}>
                  <Checkbox disabled> {translations().posOpenBanking} </Checkbox>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item className={styles.h10} name={'zashEpos'} valuePropName={'checked'}>
                  <Checkbox disabled={editMode ? !editEnabled : !zashEnabled}>{translations().zashEpos} </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row className={styles.mt10} gutter={24}>
              <Col span={12}>
                <Form.Item
                  name='terminalType'
                  rules={[{ required: true, message: translations().required }]}
                  validateTrigger={['onChange', 'onBlur']}
                  label={<p className={styles.styledP}>{translations().contractType}</p>}
                >
                  <Select
                    onChange={() => {
                      t.resetFields(['chargeType', 'subscriptionServicePeriod', 'freeSubscriptionPeriod'])
                    }}
                    options={terminalTypes}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item shouldUpdate noStyle>
                  {t =>
                    (t.getFieldValue('terminalType') === 'subscription' ||
                      t.getFieldValue('terminalType') === 'terminalPurchase') && (
                      <>
                        <Form.Item
                          name='chargeType'
                          rules={[{ required: true, message: translations().required }]}
                          validateTrigger={['onChange', 'onBlur']}
                          label={<p className={styles.styledP}>{translations().chargeType}</p>}
                        >
                          <Select onSelect={val => handleChargeTypeChange(val, form)} options={chargeTypes} />
                        </Form.Item>
                      </>
                    )
                  }
                </Form.Item>
              </Col>
            </Row>

            {t.getFieldValue('terminalType') === 'subscription' && (
              <>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      name='subscriptionServicePeriod'
                      rules={[{ required: true, message: translations().required }]}
                      validateTrigger={['onChange', 'onBlur']}
                      label={<p className={styles.styledP}>{translations().subscriptionServicePeriod}</p>}
                    >
                      <Select
                        options={
                          t.getFieldValue('chargeType') === 'byPaytek'
                            ? subscriptionServicePeriodOptionsPaytek
                            : subscriptionServicePeriodOptionsDNA
                        }
                        onSelect={e => {
                          if (form.getFieldValue('terminalType') === 'subscription' && e) {
                            setSubscriptionFee(
                              subscriptionPrices?.find(
                                s => s.posModel === Number(form.getFieldValue('model')) && s.code === e
                              )?.value || ''
                            )
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='freeSubscriptionPeriod'
                      rules={[{ required: true, message: translations().required }]}
                      validateTrigger={['onChange', 'onBlur']}
                      label={<p className={styles.styledP}>{translations().freeSubscriptionPeriod}</p>}
                    >
                      <InputNumber
                        disabled={editMode ? !editEnabled : isPaytek}
                        max={6}
                        min={0}
                        className={styles.styledNumberInput}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Form.Item shouldUpdate noStyle>
              {t =>
                t.getFieldValue('model') &&
                !isEmpty(posBundles) &&
                (t.getFieldValue('terminalType') === 'subscription' ||
                  (isISO &&
                    (t.getFieldValue('terminalType') === 'terminalPurchase' ||
                      t.getFieldValue('terminalType') === 'ownTerminal'))) && (
                  <div>
                    <Form.Item
                      name='posBundles'
                      rules={[{ required: true, message: translations().required }]}
                      validateTrigger={['onChange', 'onBlur']}
                      label={<p className={styles.styledP}>{translations().posBundles}</p>}
                    >
                      <Select
                        mode='multiple'
                        disabled={editMode ? !editEnabled : isBundlesLoading}
                        loading={isBundlesLoading}
                        options={posBundles?.map(item => ({ value: item.code, label: item.value }))}
                        onSelect={e => {
                          if (isEmpty(posBundles?.find(p => p.code === e)?.codeZash)) {
                            form.setFieldsValue({ zashEpos: false })
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                )
              }
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {t =>
                t.getFieldValue('terminalType') === 'subscription' && (
                  <>
                    <Table
                      showHeader={false}
                      bordered
                      pagination={false}
                      columns={productColumns}
                      dataSource={feesDataRegularForProducts(
                        subscriptionFee ? Number(subscriptionFee) : 0,
                        editMode,
                        editEnabled,
                        isSME
                      )}
                    />
                    <Divider />
                  </>
                )
              }
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
              {t =>
                t.getFieldValue('terminalType') === 'terminalPurchase' && (
                  <>
                    <Table
                      showHeader={false}
                      bordered
                      pagination={false}
                      columns={productColumns}
                      dataSource={terminalPurchaseData(t.getFieldValue('chargeType') === 'byDNA')}
                    />
                    <Divider />
                  </>
                )
              }
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
              {t =>
                t.getFieldValue('model') &&
                ((editMode && editEnabled) || !editMode) && (
                  <>
                    <FormSubgroupTitle>{translations().zashOffer}</FormSubgroupTitle>

                    <p className={styles.offerText}>
                      {zashEnabled
                        ? form.getFieldValue('zashEpos') === true
                          ? translations().zashOfferDescriptionAvailable
                          : translations().zashOfferTurnedOff
                        : translations().zashOfferDescriptionUnavailable}
                    </p>
                  </>
                )
              }
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
              {t =>
                !isEmpty(t.getFieldValue('terminalType')) &&
                (isISO ? true : t.getFieldValue('terminalType') !== 'ownTerminal') && (
                  <>
                    {showDeliveryInfo && storeForm && (
                      <PosDeliveryInfo
                        form={form}
                        storeForm={storeForm}
                        companyProfile={companyProfile}
                        onboardingSettings={onboardingSettings}
                        posDeliveryAddress={posDeliveryAddress}
                        posDeliveryAddressType={posDeliveryAddressType}
                        posDeliveryInstructions={posDeliveryInstructions}
                        showHeader
                        deliveryPhoneNumber={deliveryPhoneNumber}
                      />
                    )}
                  </>
                )
              }
            </Form.Item>
          </>
        )}
      </Form.Item>
    </div>
  )
}
