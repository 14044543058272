import React from 'react'
import { Bar, Circle,  } from '~/code/components/NotificationsManagement/Preview/shared'
import { Flex } from 'antd'
import { alto, candleLight } from '~/code/constants/colors'
import {
  TemplateConfigProviderType
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components'
import styles from './styles.scss'

export const LoginMockup = ({templateConfig}: {templateConfig: TemplateConfigProviderType}) => {
  const {templateForm, previewConfig} = templateConfig

  const {notificationLevelId, descriptionLine1, title} = templateForm

  const barProps = {
    width: 70,
    height: 10,
    color: alto
  }

  return (
    <div className={styles.loginContainer}>
      <div className={styles.header}>
        <Circle color={candleLight} diameter={20}/>
        <Bar width={70} height={10} color={alto}/>
      </div>
      <Flex className={styles.mockItemsContainer} gap={'small'} vertical={true}>
        <Bar {...barProps}/>
        <Bar {...barProps}/>
        <Bar {...barProps}/>
        <Bar width={'100%'} height={30} color={candleLight} />
        <div className={styles.reversedBar}><Bar {...barProps}/></div>
        <Bar width={'100%'} height={30} color={candleLight} />

        {notificationLevelId && (
          <div className={styles.banner} style={{...previewConfig.banner}}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: descriptionLine1 }}></div>
          </div>
        )}
      </Flex>
    </div>
  )
}