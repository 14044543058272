import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Col, Form, Modal, Row, Button, Select, Checkbox, Radio, Tooltip, Alert } from 'antd'
import { FormInstance } from 'antd/lib'
import { CheckCircleFilled } from '@ant-design/icons'
import { UploadOutlined } from '@ant-design/icons'
import { log } from 'dna-common'
import { useInjection } from 'dna-react-ioc'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { Input } from '~/code/components'
import { FormSubgroupTitle } from '~/code/pages/StartApplication/components'
import { SupportingDocument, Upload } from '~/code/pages/StartApplication/components/SupportingDocuments/components'
import { SalesForceSearch } from '../../../SalesForceSearch'
import { DocumentsType } from '../../models'
import { cleanValueToCompare } from './services'
import { IChangeBankDetailsModalStore } from './IChangeBankDetailsModalStore'
import { BankAccountType } from './models'
import { ChangeBankDetailsModalProps } from './props'
import { availableDocumentTypes } from './constants'
import translations from './translations'
import styles from './ChangeBankDetailsModal.scss'

export const ChangeBankDetailsModalStoreSymbol = Symbol('ChangeBankDetailsModalStore')
export const ChangeBankDetailsModal = observer((props: ChangeBankDetailsModalProps) => {
  const [form] = Form.useForm()
  const [documentType, setDocumentType] = useState<keyof DocumentsType>('' as keyof DocumentsType)
  const store = useInjection<IChangeBankDetailsModalStore>(ChangeBankDetailsModalStoreSymbol)
  const isModalOpen = store.storesDossierV2Store.isChangeBankDetailsModalOpen
  const currentEmail = () => (store.isOwnerEmailEqualToCompany ? store.email : store.ownerEmail)

  const handleSubmit = (form: FormInstance, startFromEcoSpend: boolean) => {
    store.changeBankDetails(form, startFromEcoSpend, false)
  }

  const setDefaultFields = () => {
    form.setFieldsValue({
      bankAccountType: '',
      bankAccountName: '',
      bankAccountNumber: '',
      sortCode: '',
      allAccountTypes: false
    })
  }

  const renderAdditionalDocumentUpload = (_documentType: string) => {
    return (
      <Upload
        disabled={!documentType}
        loading={store.isDocumentLoading}
        fileList={[]}
        onChange={async fileList => {
          await store.uploadDocument(fileList, documentType)
        }}
      >
        <Button icon={<UploadOutlined />}>{translations().upload}</Button>
      </Upload>
    )
  }

  useEffect(() => {
    setDefaultFields()
  }, [isModalOpen])

  useEffect(() => {
    store.processSFData(form)
  }, [store.salesForceData])

  useEffect(() => {
    if (store.verifyBankAccountResult) {
      const { type, message: resultMessage } = store.verifyBankAccountResult
      if (type === 'valid') {
        form.setFieldsValue({ bankName: resultMessage as string })
      } else {
        form.setFieldsValue({ bankName: '' })
      }
    }
  }, [store.verifyBankAccountResult])

  const onVerifyBankAccount = () => {
    store.verifyBankAccount(form)
  }

  const handleDocumentOptionChange = (option: keyof DocumentsType) => {
    setDocumentType(option)
  }

  useEffect(() => {
    form.setFieldsValue({ allAccountTypes: store.isChecked })
  }, [store.isChecked])

  const handleCancel = () => {
    setDefaultFields()
    store.setSalesForceData(false, null)
    store.closeChangeBankDetailsModal()
  }

  const isBankDetailsAreEqual = (): boolean => {
    const { sortCode, bankAccountNumber } = form.getFieldsValue() || {}
    const {
      sortCode: selectedSortCode,
      bankAccountNumber: selectedBankAccountNumber,
      isDirectDebit
    } = store.selectedBankDetail || {}
    return (
      !isDirectDebit &&
      cleanValueToCompare(sortCode) === cleanValueToCompare(selectedSortCode) &&
      cleanValueToCompare(bankAccountNumber) === cleanValueToCompare(selectedBankAccountNumber)
    )
  }

  const isChangeBankDetailsBtnDisabled = (() => {
    if (store.verifyBankAccountResult.type === 'invalid') return true
    if (
      store.ecospendNeedRestrictionChecks.requestSent &&
      !store.ecospendNeedRestrictionChecks.sortCodeIsFound &&
      store.document.length === 0
    )
      return true
    if (isBankDetailsAreEqual()) return true
    return false
  })()

  const tooltipTitle = (() => {
    if (store.verifyBankAccountResult.type === 'invalid' && store.verifyBankAccountResult.message.length <= 0) {
      return translations().validateBankAccount
    }

    if (isBankDetailsAreEqual()) return translations().changeSortCodeAccountNumber

    return ''
  })()

  return (
    <Modal
      onCancel={() => store.closeChangeBankDetailsModal()}
      {...props}
      footer={[
        store.changeBankDetailsRes?.code !== 0 && (!store.showOpenBankingVerification || store.uploadDocumentManually) && (
          <Button key={'cancelBtn'} onClick={handleCancel}>
            {translations().cancel}
          </Button>
        ),
        store.changeBankDetailsRes?.code !== 0 &&
          (!store.showOpenBankingVerification || (store.uploadDocumentManually && store.document?.length > 0)) && (
            <Tooltip key={'changeBtn'} title={tooltipTitle}>
              <Button
                type='primary'
                loading={store.isChangeBankDetailsLoading}
                disabled={isChangeBankDetailsBtnDisabled}
                onClick={() => {
                  if (store.verifyBankAccountResult.type === 'invalid') return
                  form
                    .validateFields()
                    .then(() => form.submit())
                    .catch(error => log(error))
                }}
              >
                {translations().change}
              </Button>
            </Tooltip>
          ),
        store.changeBankDetailsRes?.code === 0 && (
          <Button
            key='viewReport'
            type='primary'
            onClick={() => {
              handleCancel()
              goToRoute(Routes.BPM_PROCESSES_CHANGE_BANK_DETAILS_DOSSIER_V2)
            }}
          >
            {translations().toReport}
          </Button>
        )
      ]}
    >
      <FormSubgroupTitle>{translations().accountBlockTitle(store.selectedBankDetail?.isDirectDebit)}</FormSubgroupTitle>
      {!(store.changeBankDetailsRes?.code === 0) && (
        <SalesForceSearch store={store.salesForceSearchStore} midsList={props.midsList} />
      )}
      <Form
        form={form}
        autoComplete='off'
        layout='vertical'
        requiredMark={'optional'}
        onFinish={() => handleSubmit(form, false)}
      >
        {store.salesForceDataFound && !(store.changeBankDetailsRes?.code === 0) && (
          <>
            <div className={!store.isShowOpenBanking ? styles.show : styles.hide}>
              <Form.Item name='allAccountTypes'>
                <Checkbox checked={store.isChecked} onChange={e => store.setIsChecked(e.target.checked)}>
                  {store.selectedBankDetail?.isDirectDebit
                    ? translations().changeSettlement
                    : translations().changeDirectDebit}
                </Checkbox>
              </Form.Item>
              <Form.Item
                name='bankAccountType'
                label={translations().accountType}
                rules={[{ required: true, message: translations().accountTypeRequired }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Radio.Group disabled>
                  <Radio value={BankAccountType.Business}>{translations().business}</Radio>
                  <Radio value={BankAccountType.Personal}>{translations().personal}</Radio>
                </Radio.Group>
              </Form.Item>
              <Row gutter={16}>
                <Col xs={24} md={10}>
                  <Form.Item
                    name='bankAccountNumber'
                    label={translations().bankAccountNumberLabel}
                    rules={[
                      { required: true, message: translations().bankAccountNumberRequired },
                      { pattern: /^(\d){4}(\s)(\d){4}$/, message: translations().bankAccountNumberInvalid }
                    ]}
                    validateTrigger='onBlur'
                    wrapperCol={{ xs: 24 }}
                  >
                    <Input disabled type={'masked'} mask={'9999 9999'} placeholder={'1234 5678'} />
                  </Form.Item>
                </Col>

                <Col xs={24} md={8}>
                  <Form.Item
                    name='sortCode'
                    label={translations().sortCodeLabel}
                    rules={[
                      { required: true, message: translations().sortCodeRequired },
                      {
                        pattern: /^(\d){2}(\s)-(\s)(\d){2}(\s)-(\s)(\d){2}$/,
                        message: translations().sortCodeInvalid
                      }
                    ]}
                    validateTrigger='onBlur'
                    wrapperCol={{ xs: 24 }}
                  >
                    <Input disabled type={'masked'} mask={'99 - 99 - 99'} placeholder={'12 - 34 - 56'} />
                  </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                  <Form.Item label={' '} required>
                    <Button
                      className={styles.verifyButton}
                      loading={store.isVerifyBankLoading}
                      onClick={onVerifyBankAccount}
                      icon={
                        store.verifyBankAccountResult?.type === 'valid' ? (
                          <CheckCircleFilled className={styles.checkCircle} />
                        ) : null
                      }
                    >
                      {translations().validate}
                    </Button>
                  </Form.Item>
                </Col>

                {store.verifyBankAccountResult.type !== 'valid' && store.verifyBankAccountResult.message.length > 0 && (
                  <Col xs={24}>
                    {(store.verifyBankAccountResult.message as string[]).map(errMsg => (
                      <div key={errMsg} className={styles.errMsgVerifyBankAccount}>
                        {errMsg}
                      </div>
                    ))}
                  </Col>
                )}
              </Row>
              {store.verifyBankAccountResult.type === 'valid' && (
                <Row>
                  <Col xs={24} md={24}>
                    <Form.Item name='bankName' label={translations().bankNameLabel} required>
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={24} md={24}>
                  <Form.Item
                    name='bankAccountName'
                    label={translations().accountNameLabel}
                    tooltip={translations().accountNameTooltip}
                    rules={[{ required: true, message: translations().accountNameRequired }]}
                    validateTrigger='onBlur'
                  >
                    <Input disabled placeholder={translations().accountNamePlaceholder} />
                  </Form.Item>
                </Col>
              </Row>
              {store.ecospendNeedRestrictionChecks.requestSent && store.verificateBankAccountRes.verified === false && (
                <div>
                  <b>{translations().automatedVerification}</b>
                  <Alert
                    className={styles.resultsHeader}
                    message={
                      <>
                        {translations().verifResult('FAILED')}
                        {store.ecospendNeedRestrictionChecks.sortCodeIsFound
                          ? translations().bankSupportEcoSpend
                          : translations().bankNotSupportEcoSpend}
                      </>
                    }
                    type='warning'
                  />
                  {store.ecospendNeedRestrictionChecks.requestSent && (
                    <div>
                      <p>
                        <b>{translations().whatsNext}</b>
                      </p>
                      {store.ecospendNeedRestrictionChecks.sortCodeIsFound
                        ? translations().twoOptions
                        : translations().optionTwo}
                      {store.ecospendNeedRestrictionChecks.sortCodeIsFound && (
                        <ol>
                          <li>
                            {translations().optionOnePartOne}
                            <br />
                            {translations().optionOnePartTwo}
                          </li>
                          <b>OR</b>
                          <li>{translations().optionTwo}</li>
                        </ol>
                      )}
                    </div>
                  )}
                  {store.ecospendNeedRestrictionChecks.sortCodeIsFound === false && (
                    <Alert
                      className={styles.resendSection}
                      message={<p className={styles.errorMessage}>{translations().doesntSupportOB}</p>}
                      type='warning'
                    />
                  )}
                </div>
              )}
              {((store.changeBankDetailsRes?.code !== 0 && store.uploadDocumentManually) ||
                (store.ecospendNeedRestrictionChecks.requestSent &&
                  !store.ecospendNeedRestrictionChecks.sortCodeIsFound)) && (
                <>
                  <div className={styles.errMsgVerifyBankAccount}>{store.changeBankDetailsRes?.message}</div>
                  <div>{translations().bankStatementTitle}</div>
                  {!store.verificateBankAccountRes.verified && (
                    <>
                      {store.documentTypes.map(d => (
                        <SupportingDocument
                          key={d}
                          title={translations()[d]}
                          fileList={store.documentsMap[d]}
                          onChange={async (fileList, file) => {
                            const isDeleted = await store.deleteFile(d, file?.name)
                            if (!isDeleted) return
                            store.removeDocument(d, fileList)
                          }}
                          loading={store.isDocumentLoading}
                        />
                      ))}
                      <Row justify={'space-between'}>
                        <Col xs={18}>
                          <Form.Item name={'documentType'} key={'documentType'}>
                            <Select
                              options={availableDocumentTypes}
                              onChange={handleDocumentOptionChange}
                              value={documentType}
                              placeholder={translations().documentType}
                            />
                          </Form.Item>
                        </Col>
                        <Col>{renderAdditionalDocumentUpload(documentType)}</Col>
                      </Row>
                    </>
                  )}
                </>
              )}
            </div>

            {store.isShowOpenBanking && !(store.changeBankDetailsRes?.code === 0) && (
              <>
                <p>{translations().startOBRequestInfo}</p>
                <p>{translations().startOBRequestInfo2}</p>
                <p>
                  <b>{translations().info3}</b>
                </p>
                <div>
                  {translations().accountDetailsInfo(
                    store.companyName,
                    form.getFieldValue('bankAccountNumber')?.replace(/\s/g, ''),
                    form.getFieldValue('sortCode')?.replace(/\s/g, '')
                  )}
                  <b>{`${translations().accountOwnerEmail} ${store.email} ?`}</b>

                  <div className={styles.marginOptions}>
                    <Radio.Group
                      name='isOwnerEmailEqualToCompany'
                      onChange={e => store.setIsOwnerEmailEqualToCompany(e.target.value)}
                      value={store.isOwnerEmailEqualToCompany}
                    >
                      <Radio value={true}>{translations().yes}</Radio>
                      <Radio value={false}>{translations().no}</Radio>
                    </Radio.Group>
                  </div>

                  {!store.isOwnerEmailEqualToCompany && (
                    <div className={styles.resultsHeader}>
                      <Input
                        name='ownerEmail'
                        placeholder={'me@company.co.uk'}
                        textTransform={'lowercase'}
                        autoComplete='off'
                        onChange={e => store.setOwnerEmail(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            {store.showOpenBankingVerification && (
              <>
                <Row gutter={[8, 16]}>
                  {store.isShowOpenBanking ? (
                    <>
                      {!(store.changeBankDetailsRes?.code === 0) && (
                        <>
                          <Col>
                            <Button
                              loading={store.isChangeBankDetailsLoading}
                              disabled={!store.isOwnerEmailEqualToCompany && store.ownerEmail?.length === 0}
                              type='primary'
                              onClick={() => {
                                store.changeBankDetails(form, true, true, currentEmail())
                              }}
                            >
                              {translations().sendOpenBankingRequest}
                            </Button>
                          </Col>

                          <Col span={8}>
                            <Button
                              className={styles.fullWidth}
                              onClick={() => {
                                form.resetFields(['ownerEmail'])
                                form.setFieldValue('isOwnerEmailEqualToCompany', true)
                                store.setShowOpenBanking(false)
                              }}
                            >
                              {translations().detailEdit}
                            </Button>
                          </Col>
                          <Col span={8} />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {!(store.changeBankDetailsRes?.code === 0) && (
                        <>
                          <Col span={8}>
                            {
                              <Button
                                className={styles.fullWidth}
                                type='primary'
                                onClick={() => {
                                  store.setShowOpenBanking(true)
                                  store.setUploadDocumentManually(false)
                                  store.clearData()
                                }}
                              >
                                {translations().openBankingVerification}
                              </Button>
                            }
                          </Col>
                          <Col span={8}>
                            <Button
                              className={styles.fullWidth}
                              type='primary'
                              onClick={() => {
                                store.setUploadDocumentManually(true)
                              }}
                            >
                              {translations().uploadBankStatement}
                            </Button>
                          </Col>
                          <Col span={8} />
                        </>
                      )}
                    </>
                  )}
                </Row>
              </>
            )}
          </>
        )}
        {store.changeBankDetailsRes?.code === 0 && (
          <>
            {store.showEcospendFinalSuccessMessage ? (
              <>
                <FormSubgroupTitle>{translations().openBankingVerificationRequest}</FormSubgroupTitle>
                <Row>
                  <p>{translations().openBankingVerificationSentText}</p>
                </Row>
              </>
            ) : (
              <Row>
                <p>{translations().changeBankDetailsSuccessText}</p>
              </Row>
            )}
          </>
        )}
      </Form>
    </Modal>
  )
})
