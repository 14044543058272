import React from 'react'
import { FullBlockMockup, SectionMockup } from '~/code/components/NotificationsManagement'

import { NotificationDisplayTypeEnum } from '~/code/models'
import { useTemplateConfig } from '~/code/hooks'


export const FullBlockPreview = () => {
  const config = useTemplateConfig()
  const {templateForm} = config
  const {notificationDisplayTypeId, notificationContentAreaId} = templateForm

  return notificationDisplayTypeId === NotificationDisplayTypeEnum.BLOCK && notificationContentAreaId === 'app' ? (
    <FullBlockMockup templateConfig={config}/>
  ) : <SectionMockup />
}