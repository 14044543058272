import translations from './translations'

translations.add('en', {
  title: 'Team Management',
  edit: 'Edit Access',
  cancel: 'Cancel',
  submit: 'Save',
  invite: 'Invite',
  isDefaultTooltip:
    'If the user has access to multiple merchants under the email address, this flag will set the merchant that the user will log in to by default.',
  shopPlaceholder: 'Select shop',
  specificPermissions: 'Specific permissions',
  formFields: {
    email: 'Email',
    store: 'Store',
    firstName: 'First name',
    lastName: 'Last name',
    teammatePermissions: 'User access',
    inviteAsAdmin: 'Admin',
    inviteAsRestrictedAccess: 'Limited access',
    accountantAccess: 'Finance access',
    customAccess: 'Custom access',
    grantedPermissions: 'Permissions settings',
    grantedSpecificPermissions: 'Specific permissions settings',
    accessLevels: 'access levels',
    sectionName: 'Section name',
    noAccess: 'No access',
    readAccess: 'Read only',
    fullAccess: 'Full access',
    sendWelcomeEmail: 'Send Welcome Email?',
    isDefault: 'Set as default merchant',
    merchant: 'Merchant',
    sections: {
      overview: 'Overview',
      online_payments: 'Online payments',
      pos_payments: 'POS payments',
      payment_links: 'Payment links',
      virtual_terminal: 'Virtual terminal',
      zash_epos: 'Zash ePOS',
      settlements: 'Settlements',
      reports: 'Reports',
      payment_methods: 'Settings. Online payment methods',
      teammates: 'Settings. Team management',
      invoices: 'Invoices',
      pos_amex_payments: 'POS American Express',
      chargebacks: 'Chargebacks',
      card_issuing: 'Card issuing',
      'merchant.profile': 'Settings. Company profile',
      'merchant.bank_accounts': 'Settings. Bank accounts',
      'merchant.pos_stores': 'Settings. POS outlets',
      'merchant.ecom_stores': 'Settings. Online outlets'
    }
  },
  virtual_terminal_refunds: 'Ad-hoc refunds via VT',
  virtualTerminalRefundsHint:
    'Ad-hoc (standalone) refunds via Virtual Terminal won’t work unless approved by the risk team and configured on the merchant account',
  formErrors: {
    emailRequired: 'email is required',
    shopRequired: 'store is required',
    enterValidEmail: 'please enter valid email',
    firstNameRequired: 'first name is required',
    lastNameRequired: 'last name is required',
    merchantRequired: 'merchant is required',
    permissionRequired: 'select at least one permission'
  }
})
