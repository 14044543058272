import React from 'react'
import { observer } from 'mobx-react'
import {
  BannerPreview, FullBlockPreview, PopupPreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplatePreview/components/WebPreview/components'
import {
  LoginBannerPreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/pages/CreateTemplate/components/TemplatePreview/components/shared/LoginBannerPreviev/LoginBannerPreview'
import { NotificationDisplayTypeEnum } from '~/code/models'
import { useTemplateConfig } from '~/code/hooks'

export const WebPreview = observer(() => {
  const {templateForm}  = useTemplateConfig()

  const renderPreview = () => {
    const {notificationDisplayTypeId, notificationContentAreaId} = templateForm

    switch (notificationDisplayTypeId) {
      case(NotificationDisplayTypeEnum.BANNER):
        if (notificationContentAreaId === 'login') return <LoginBannerPreview/>
        return <BannerPreview/>
      case(NotificationDisplayTypeEnum.POPUP):
        return <PopupPreview/>
      case(NotificationDisplayTypeEnum.BLOCK):
        return <FullBlockPreview/>
      default: return null
    }
  }

  return (
    <>{renderPreview()}</>
  )
})